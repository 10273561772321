import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Error from '../../components/Error'
import Loading from '../../components/Loading'
import styled from 'styled-components'

import Table from "../../components/Table";
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllData } from '../../store/product_slice'
import DeleteButton from '../../components/DeleteButton';
import { API_URL } from '../../utils/constants'


const List = () => {


  const { error, loading, data } = useSelector(state => state.products);
  const dispatch = useDispatch();


  // const { fetchCOLORS: fetchData, COLORS, COLORS_error: error, COLORS_loading: loading } = useGlobalContext();
  const NameAlisa = "Produit";
  const NameAlisPlurals = "Produits";
  const add_edit_delete_link = "products" // root

  useEffect(() => {
    dispatch(fetchAllData());
  }, [dispatch]);

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <Error />
  }

  const dataSet = data;
  const columns = [
    {
      "data": "image",
      "title": "Image",
      "render": function (data) {
        // return '<img width="100" height="100" src="' + data + '"/>';
        return <img width="100" height="100" src={`${API_URL}${data}`} />;
      }
    },
    {
      "data": "sku",
      "title": "SKU",
      // "width": "20%"
    },
    {
      "data": "name",
      "title": "Titre",
      "width": "20%"
    },


    {
      "data": "price",
      "title": "Prix",
      "render": function (data) {
        return (data).toFixed(3);
      }
    },
    {
      "data": "colors",
      "title": "Couleurs",
      "width": "15%",
      "render": function (data) {

        // console.log(data);
        if (data) {

          const colors = data.map((d, index) => {
            return <button key={index} className='color-btn' style={{ background: d.code }} title={d.name} ></button>
          });

          return <div className='colors'>{colors}</div>;
        }
        return <></>;
      }
    },
    {
      "data": "size",
      "title": "Taille",
      "width": "15%",
      "render": function (data) {
        if (data) {

          const sizes = data.map((d, index) => {
            return <button key={index} className='size-btn' title={d.name}>{d.name}</button>
          });

          return <div className='colors'>{sizes}</div>;
        }
        return <></>;
      }
    },
    {
      "data": "category", "title": "Category", "render": function (data) {
        if (data) {
          return data.name;
        }
        return <></>;
      }
    },
    { "data": "company", "title": "Marque" },
    {
      "data": "stockItems",
      "title": "Stock",
      "render": function (data) {
        if (data.length > 0) {
          let total_stock = 0;
          data.map((i) => {
            total_stock += i.quantity;
          })
          return <>{total_stock}</>
        }
        return <>0</>
      }
    },
    {
      "data": "_id",
      "title": "Actions",
      "width": "20%",
      "render": function (data) {
        return (
          <>
            <Link to={`/products/stock/${data}`} className='btn btn-warning mx-2  btn-sm'>Stock</Link>
            <Link to={`/products/edit/${data}`} className='btn btn-success mx-2  btn-sm'>Modifier</Link>
            <DeleteButton to={`/products/delete/${data}`} />
          </>
        )
        // '<a class="btn btn-success mx-2  btn-sm" href="/products/edit/' + data + '">Modifier</a>'
        //   + '<a class="btn btn-danger btn-sm" href="/products/delete/' + data + '"  onclick="return confirm(\'es-tu sûr?\')">Supprimer</a>'
      }
    },
  ];


  return <Wrapper>
    <main>
      <div className="container-fluid px-4">
        <h1 className="mt-4">Gestion des {NameAlisPlurals}</h1>
        <ol className="breadcrumb mb-4">
          <li className="breadcrumb-item active"></li>
        </ol>


        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Liste des {NameAlisPlurals}
            <Link to={`/${add_edit_delete_link}/add/`} className="btn btn-primary btn-sm float-end mx-1">Créer</Link>
            <Link to={`/${add_edit_delete_link}/stockgloboal/`} className="btn btn-warning btn-sm float-end mx-1">Stock Global</Link>

          </div>
          <div className="card-body">
            <Table data={dataSet} columns={columns} />
          </div>
        </div>
      </div>
    </main>
  </Wrapper>
}

const Wrapper = styled.section`

img{
  object-fit: cover;
  width: 100px;
  height: 100px;
}
.colors {
    display: flex;
    grid-template-columns: 40px 1fr;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}
.size-btn {
    width: auto;
}

`

export default List
