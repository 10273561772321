import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import styled from 'styled-components'
import FormMessage from '../../components/FormMessage'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import LoadingButton from '../../components/LoadingButton';

import { useSelector, useDispatch } from 'react-redux';
import { fetchSingleData, editData, setData, addNewRow, updateTotal } from '../../store/order_slice';
import { fetchAllData as get_all_products } from '../../store/product_slice';
import Loading from '../../components/Loading'
import EditOrderItem from './EditOrderItem'
import { formatDecimal } from '../../utils/helpers'
import { FcPlus } from 'react-icons/fc'
import { FaPhoneAlt, FaShippingFast } from 'react-icons/fa'

const Edit = () => {


  const { loading, error, data, success } = useSelector(state => state.orders);
  const [customShipping, setCustomShipping] = useState(0);

  const dispatch = useDispatch();



  const { id } = useParams();
  const NameAlisa = "Commande";
  const NameAlisPlurals = "Commandes";
  const BackLink = "/orders"

  // const [formData, setFormData] = useState({
  //   customer: {
  //     name: '',
  //     ville: '',
  //     address: '',
  //     phone: '',
  //   },
  //   total_amount: 0,
  //   shipping_fees: 0,
  //   message: "",
  //   msgType: "",
  // });






  useEffect(() => {
    dispatch(fetchSingleData(id));
    dispatch(get_all_products())
  }, [id, dispatch])




  useEffect(() => {
    if (data)
      dispatch(updateTotal());
  }, [data.shipping_fees, data.discount, data.orderItems, dispatch])


  useEffect(() => {
    if (!loading && !error) {
      setCustomShipping(formatDecimal(data?.shipping_fees))
    }
  }, [loading])



  useEffect(() => {
    if (success) {
      toast.success(NameAlisa + " modifié avec succès", { theme: "colored" });
    }
    if (error) {
      toast.error(error, { theme: "colored" });
    }
  }, [success, error])




  const handleInput = (e) => {
    const model = e.target.getAttribute("data-model");
    const value = (e.target.type === 'checkbox' ? e.target.checked : e.target.value);

    if (e.target.name === "shipping_fees")
      setCustomShipping(value);

    if (model) {
      const modeldata = data[model];

      dispatch(setData({ data: { ...data, [model]: { ...modeldata, [e.target.name]: value } } }))
    }
    else
      // dispatch(setData({ ...data, [e.target.name]: value }))
      dispatch(setData({ data: { ...data, [e.target.name]: value } }))

  };

  const FormHandler = (e) => {
    e.preventDefault();

    dispatch(editData(id, data));
  };




  if (loading) {
    return <Loading />
  }

  // if (error) {
  //   return <Error />

  // }




  return <Wrapper>
    <div className="container-fluid px-4">
      <h1 className="mt-4">Gestion des {NameAlisPlurals}</h1>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item active"></li>
      </ol>


      <div className="card mb-4">
        <div className="card-header">
          <i className="fas fa-table me-1"></i>
          Modification du {NameAlisa}
        </div>
        <div className="card-body">

          <FormMessage />
          <form onSubmit={FormHandler} >


            <div className='row'>
              <div className="row g-3 col-md-6">
                <div className="col-md-10">
                  <label htmlFor="customer_name">Nom du Client</label>
                  <input type="text" className="form-control" required="required" data-model="customer" id="customer_name" name="name" value={data?.customer?.name} onChange={handleInput} placeholder={`Entrez le nom du ${NameAlisa}`} />
                </div>

                <div className="col-md-10">
                  <label htmlFor="name">Ville</label>
                  <select className="form-select" required="required" data-model="customer" id="ville" name="ville" value={data?.customer?.ville} onChange={handleInput} placeholder={`Entrez le nom du ${NameAlisa}`} >
                    <option value="Ariana">Ariana</option>
                    <option value="Béja">Béja</option>
                    <option value="Ben Arous">Ben Arous</option>
                    <option value="Bizerte">Bizerte</option>
                    <option value="Gabès">Gabès</option>
                    <option value="Gafsa">Gafsa</option>
                    <option value="Jendouba">Jendouba</option>
                    <option value="Kairouan">Kairouan</option>
                    <option value="Kasserine">Kasserine</option>
                    <option value="Kébili">Kébili</option>
                    <option value="La Manouba">La Manouba</option>
                    <option value="Le Kef">Le Kef</option>
                    <option value="Mahdia">Mahdia</option>
                    <option value="Médenine">Médenine</option>
                    <option value="Monastir">Monastir</option>
                    <option value="Nabeul">Nabeul</option>
                    <option value="Sfax">Sfax</option>
                    <option value="Sidi Bouzid">Sidi Bouzid</option>
                    <option value="Siliana">Siliana</option>
                    <option value="Sousse">Sousse</option>
                    <option value="Tataouine">Tataouine</option>
                    <option value="Tozeur">Tozeur</option>
                    <option value="Tunis">Tunis</option>
                    <option value="Zaghouan">Zaghouan</option>
                  </select>
                </div>
                <div className="col-md-10">
                  <label htmlFor="name">Adresse</label>
                  <input type="text" className="form-control" required="required" data-model="customer" id="address" name="address" value={data?.customer?.address} onChange={handleInput} placeholder={`Entrez le nom du ${NameAlisa}`} />
                </div>

                <div className="col-md-10">
                  <label htmlFor="name">Téléphone</label>
                  <input type="number" className="form-control" required="required" data-model="customer" id="phone" name="phone" value={data?.customer?.phone} onChange={handleInput} placeholder={`Entrez le nom du ${NameAlisa}`} />
                </div>

              </div>

              <div className="row g-3  col-md-6">
                <div className="col-md-6" role="group" aria-label="Basic checkbox toggle button group">
                  <input className="btn-check" type="checkbox" name="isConfirmed" checked={data.isConfirmed} onChange={handleInput} id="isConfirmed" />
                  <label className="btn btn btn-outline-success mt-4 btn-lg" htmlFor="isConfirmed">
                    <FaPhoneAlt /> {data.isConfirmed ? "Commande Confirmé" : "En attente de confirmation..."}
                  </label>
                </div>

                <div className="col-md-6" role="group" aria-label="Basic checkbox toggle button group">
                  <input className="btn-check" type="checkbox" name="isShipped" checked={data.isShipped} onChange={handleInput} id="isShipped" />
                  <label className="btn btn btn-outline-success mt-4 btn-lg" htmlFor="isShipped">
                    <FaShippingFast /> {data.isShipped ? "Commande Expédié" : "En attente d'expédition "}

                  </label>
                </div>


              </div>

            </div>




            <div className="table-responsive mt-3">
              <table className="table">
                <thead>
                  <tr >
                    <th className='col-1'>Produit</th>
                    <th className='col-3'>Titre</th>
                    <th className='col-2'>Taille</th>
                    <th className='col-2'>Couleur</th>
                    <th className='col-1' >Quantité</th>
                    <th className='col-1' >Prix</th>
                    <th className='col-1' >Total</th>
                    <th className='col-1' ><FcPlus onClick={(e) => { dispatch(addNewRow()) }} /></th>
                  </tr>
                </thead>
                <tbody>

                  {
                    data?.orderItems?.map((item, index) => {
                      return (
                        <EditOrderItem key={index} index={index} {...item} />
                      )
                    })
                  }

                  <tr >
                    <td colSpan={7}>&nbsp;</td>
                  </tr>
                  <tr >
                    <td colSpan={6} className="text-right footer-title">Sous-total:</td>
                    <td className="col-1 " >{data?.total_amount?.toFixed(3)}</td>
                  </tr>
                  <tr >
                    <td colSpan={6} className="text-right footer-title">Frais de livraison:</td>
                    <td className="col-1 " >
                      {
                        <input type="number" className="form-control" required="required" id="shipping_fees" name="shipping_fees" value={customShipping || data?.shipping_fees} onChange={handleInput}
                          onBlur={(e) => {
                            if (e.target.value)
                              setCustomShipping(formatDecimal(e.target.value))
                          }}
                        />
                      }
                    </td>
                  </tr>
                  <tr >
                    <td colSpan={6} className=" text-right footer-title">Remise(%):</td>
                    <td className="col-1 " >
                      {
                        <input type="number" className="form-control" required="required" id="discount" placeholder='0' name="discount" value={data?.discount} onChange={handleInput}

                        />
                      }
                    </td>
                  </tr>
                  <tr >
                    <td colSpan={6} className=" text-right footer-title">Total:</td>
                    <td className="col-1 " >{data.total}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="form-group">
              <Link to={BackLink} className='btn btn-space btn-danger'>Retour</Link>
              <button type="submit" className="btn btn-primary">
                {loading ? <LoadingButton /> : "Enregistrer"}

              </button>
            </div>


          </form>
        </div>
      </div>
    </div>
  </Wrapper>
}


const Wrapper = styled.main`

input.form-control,.form-select {
  width: auto;
  min-width:100%
}

.btn-check:checked+.btn-outline-success, .btn-check:active+.btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #0bbb15;
    border-color: #2a7c09;
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #0bbb15;
    border-color: #198754;
}

.img-cart {
    display: block;
    max-width: 100px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}
table tr td{
    border:1px solid #FFFFFF;    
}

table tr th {
    background:#eee;    
}
.footer-title {
    font-weight: 700;
}
`

export default Edit
