import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaBars, FaUser } from 'react-icons/fa'
import defaultlogo from './../assets/logo.png'
import { postLogout, toggleSidebar, ToggleUserMenu } from '../store/users_slice'
import { useSelector, useDispatch } from 'react-redux';
import { API_URL } from '../utils/constants'

const Navbar = () => {


  const { settings, isUserMenuOpen } = useSelector(state => state.users);
  const dispatch = useDispatch();
  const [logo, setLogo] = useState(defaultlogo);


  useEffect(() => {
    if (settings?.logo)
      setLogo(`${API_URL}${settings?.logo?.url}`);
  }, [settings])


  return <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
    <Link className="navbar-brand ps-3 " to="/"> <img src={logo} alt="LinaShoes" className='logo' /></Link>
    <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" to="" onClick={() => dispatch(toggleSidebar())} ><FaBars /></button>

    <span className='d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0'></span>
    <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
      <li className="nav-item dropdown">
        <span onClick={() => dispatch(ToggleUserMenu())} className={`nav-link dropdown-toggle ${isUserMenuOpen ? "show" : null}`} id="navbarDropdown" role="button" to="#" data-bs-toggle="dropdown" aria-expanded="true"><FaUser /></span>
        <ul className={`dropdown-menu dropdown-menu-end ${isUserMenuOpen ? "show" : null}`} aria-labelledby="navbarDropdown" data-bs-popper='none' >
          <li><Link className="dropdown-item" to="/settings" onClick={() => dispatch(ToggleUserMenu())}  >Paramêtres</Link></li>
          <li><Link className="dropdown-item" to="#" onClick={() => { (dispatch(postLogout())); dispatch(ToggleUserMenu()); }} >Déconnexion</Link></li>
        </ul>
      </li>
    </ul>
  </nav>
}


export default Navbar
