export const formatDecimal = (number) => {
    return Number(number).toFixed(3);
}
export const formatPrice = (number) => {
    const newNumber = Intl.NumberFormat('tn-TN', {
        style: 'currency',
        currency: 'TND'
    }).format(number);

    return newNumber;

}
export const getUniqueValues = (data, type, subtype) => {
    let unique = data.map((item) => item[type])

    if (subtype) {
        unique = unique.flat();
        unique = unique.map((item) => {
            if (typeof item !== undefined && item && typeof (item[subtype]) !== undefined && item[subtype] !== null) {
                return item[subtype]
            }
        }).filter((i) => i !== undefined)
    }
    return ['tous', ...new Set(unique.sort())]
}

export const isWhite = (str) => {
    // fiddle this value to set stricter rules for what is white.
    var whiteLimit = 200,
        r, g, b;

    r = parseInt("0x" + str.substring(1, 3));
    g = parseInt("0x" + str.substring(3, 5));
    b = parseInt("0x" + str.substring(5, 7));
    if (r < whiteLimit || b < whiteLimit || g < whiteLimit) {
        return false;
    }
    return true;
}
