import React, { useState, useEffect } from 'react'
import defaultlogo from './../../assets/logo.png';
import { useLocation, useNavigate } from 'react-router-dom'
import FormMessage from "./../../components/FormMessage";
import LoadingButton from '../../components/LoadingButton';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import { postLogin } from '../../store/users_slice';
import { API_URL } from '../../utils/constants';


const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [logo, setLogo] = useState(defaultlogo);


    const { error: loginError, userInfo, loading: loginLoading, settings } = useSelector(state => state.users);
    const dispatch = useDispatch();


    let history = useNavigate();
    const lastLocation = useLocation();

    // const { redirectTo } = queryString.parse(location.search);
    useEffect(() => {

        let redirect_to = lastLocation?.state?.from || "/";
        if (userInfo) {
            history(redirect_to);
        }
        if (loginError) {
            toast.error(loginError, { theme: "colored" });
        }
    }, [userInfo, history, loginError])


    useEffect(() => {
        if (settings?.logo)
            setLogo(`${API_URL}${settings?.logo?.url}`);

    }, [settings])



    const FormmHandler = (e) => {
        e.preventDefault();
        dispatch(postLogin(email, password))
    };

    return (

        <section className="h-100 gradient-form">

            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-xl-4">
                        <div className="card rounded-3 text-black">
                            <div className="row g-0">
                                <div className="col-lg-12">
                                    <div className="card-body p-md-6 mx-md-4">

                                        <div className="text-center">


                                            <img src={logo} className="logo" />

                                            <h4 className="mt-1 mb-5 pb-1">ADMIN PANEL</h4>
                                        </div>
                                        <FormMessage />
                                        <form onSubmit={FormmHandler}>
                                            <p>Veuillez vous connecter à votre compte</p>

                                            <div className="form-outline mb-2">
                                                <input autoFocus type="text" value={email} onChange={(e) => setEmail(e.target.value)} id="form2Example11" className="form-control" autoComplete="on"
                                                    placeholder="Nom d'utilisateur" />
                                                <label className="form-label" htmlFor="form2Example11">Nom d'utilisateur</label>
                                            </div>

                                            <div className="form-outline mb-2">
                                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} id="form2Example22" className="form-control" placeholder='Mot de passe' autoComplete="on" />
                                                <label className="form-label" htmlFor="form2Example22">Mot de passe</label>
                                            </div>

                                            <div className="text-center pt-1 mb-5 pb-1">
                                                <button className="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3 pink-btn" type="submit">
                                                    {loginLoading ? <LoadingButton /> : "Connexion"}
                                                </button>
                                            </div>

                                        </form>

                                    </div>
                                </div>
                                <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                                    <div className="text-white px-3 py-4 p-md-5 mx-md-4">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Login