import React, { useEffect } from 'react';
import { Navbar, Sidebar } from './index';
import { useSelector, useDispatch } from 'react-redux';
import { getSettings } from '../store/users_slice';
// import FormMessage from './FormMessage';
import { ToastContainer } from 'react-toastify';
const Layout = ({ children }) => {


    // const { isSidebarOpen } = useUserContext()
    const { userInfo, isSidebarOpen, settings } = useSelector(state => state.users);
    const dispatch = useDispatch()
    useEffect(() => {
        if (!settings)
            dispatch(getSettings());
    }, [dispatch])

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className={`${isSidebarOpen ? 'App' : 'App sb-sidenav-toggled'} `}>


                {userInfo && <Navbar />}
                <div id={userInfo ? `layoutSidenav` : ""}>
                    {userInfo && <Sidebar />}
                    <div id={userInfo ? `layoutSidenav_content` : ""}>

                        {children}

                    </div>
                </div>

            </div>
        </>
    )
}

export default Layout;
