import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { categories_url as api_url } from "../utils/constants";



const categoriesSlice = createSlice({
    name: "categories",
    initialState: {
        data: false,//to store the added & the edited & all 
        loading: false,
        error: false,
        success: false,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = true
            state.error = false
            state.success = false
        },
        setError: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.success = false
        },
        setData: (state, action) => {

            state.data = action.payload.data
            state.loading = false
            state.error = false
            state.success = action.payload.success
        },
        setAllData: (state, action) => {
            state.data = action.payload
            state.loading = false
            state.error = false
            state.success = false
        },
        setSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.success = action.payload
        }
    }
})
export default categoriesSlice;

// export const colorsActions = colorsSlice.actions; option 1 
export const { setError, setLoading, setData, setAllData, setSuccess } = categoriesSlice.actions;// option 2

export const add = data => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {

        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const response = await axios.post(api_url, data, config);
        const res_data = response.data;
        await dispatch(setData({ data: res_data, success: true }))
    }
    catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }
}

export const fetchSingleData = id => async (dispatch, getState) => {
    dispatch(setLoading(true));

    try {
        const response = await axios.get(`${api_url}${id}`);
        const res_data = response.data;

        dispatch(setData({ data: res_data, success: false }));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));

    }

}

export const fetchAllData = () => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const response = await axios.get(api_url);
        const res_data = response.data;
        // console.log(COLORS);

        dispatch(setAllData(res_data));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));

    }

}

export const deleteSingleData = (id) => async (dispatch, getState) => {
    dispatch(setLoading(true));

    try {

        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        await axios.delete(`${api_url}${id}`, config);
        dispatch(setSuccess(true));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }

}

export const editData = (id, data) => async (dispatch, getState) => {
    dispatch(setLoading())
    try {

        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const response = await axios.put(`${api_url}${id}`, data, config);
        const res_data = response.data;

        dispatch(setData({ data: res_data, success: true }))
    }
    catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }
}