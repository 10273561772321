import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { settings_url, USERS_url } from "../utils/constants";

const localUserInfo = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;


const usersSlice = createSlice({
    name: "users",
    initialState: {
        userInfo: localUserInfo,
        user: false,//to store the added & the edited & all 
        loading: false,
        error: false,
        success: false,
        isSidebarOpen: true,
        isUserMenuOpen: false,
        settings: false
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = true
            state.error = false
            state.success = false

        },
        setError: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.success = false

        },
        setSetting: (state, action) => {
            state.settings = action.payload
            // state.loading = false
            // state.error = false
            // state.success = false
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload
            state.loading = false
            state.error = false
            state.success = true
        },
        setUserData: (state, action) => {
            state.user = action.payload
            state.loading = false
            state.error = false
        },
        setUsers: (state, action) => {
            state.user = action.payload
            state.loading = false
            state.error = false
            state.success = false

        },
        setSuccess: (state, action) => {
            state.success = true
            state.error = false
            state.loading = false
        },
        initUserData: (state, action) => {
            state.userInfo = null
        },
        toggleSidebar: (state, action) => {
            state.isSidebarOpen = !state.isSidebarOpen;
        },
        ToggleUserMenu: (state, action) => {
            state.isUserMenuOpen = !state.isUserMenuOpen;
        },

    }
},



)
export default usersSlice;
// export const colorsActions = colorsSlice.actions; option 1 
export const { setError, setLoading, setUserInfo, setUserData, initUserData, toggleSidebar, ToggleUserMenu, setSetting, setSuccess, setUsers } = usersSlice.actions;// option 2


export const postLogin = (email, password) => async dispatch => {
    dispatch(setLoading(true));

    axios.post(`${USERS_url}login`, { email, password }).then(res => {
        console.log(res);
        if (res.status === 200) {

            if (!res.data.isAdmin) {
                dispatch(setError("Vous n'avez pas accès à cette interface"));

            } else {
                dispatch(setUserInfo({ ...res.data, timestamp: new Date().getTime() }));
                localStorage.setItem("user", JSON.stringify({ ...res.data, timestamp: new Date().getTime() }))
            }
        }
    }).catch(error => {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));

    })
}

export const postLogout = () => dispatch => {
    localStorage.removeItem("user")
    dispatch(initUserData());
}

export const getSettings = () => async dispatch => {
    // dispatch(setLoading(true));
    try {
        const response = await axios.get(settings_url);
        const settings = response.data;

        dispatch(setSetting(settings));

    } catch (error) {
        console.log(error);
        // dispatch(setError(error));

    }

}

export const fetchSingleUser = (id) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {

        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const response = await axios.get(`${USERS_url}${id}`, config);
        const USERS = response.data;
        dispatch(setUserData(USERS));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }

}

export const addUSERS = (data) => async (dispatch, getState) => {
    dispatch(setLoading())
    try {

        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const response = await axios.post(USERS_url, data, config);
        const USERS = response.data;
        console.log(USERS);
        dispatch(setUserData(USERS));
        dispatch(setSuccess());
    }
    catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }

}
export const editUSERS = (id, data) => async (dispatch, getState) => {
    dispatch(setLoading())
    try {
        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const response = await axios.put(`${USERS_url}${id}`, data, config);
        const USERS = response.data;

        dispatch(setUserData(USERS))
        dispatch(setSuccess())
    }
    catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }

}


export const deleteSingleUSERS = (id) => async (dispatch, getState) => {



    dispatch(setLoading());
    try {
        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const response = await axios.delete(`${USERS_url}${id}`, config);
        dispatch(setSuccess());

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;

        dispatch(setError(message));

    }

}


export const getUsers = () => async (dispatch, getState) => {


    dispatch(setLoading());
    try {
        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const response = await axios.get(USERS_url, config);
        const users = response.data;

        dispatch(setUsers(users));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));

    }

}