import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Error from '../../components/Error'
import Loading from '../../components/Loading'
import styled from 'styled-components'

import Table from "../../components/Table";
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllStocks } from '../../store/product_slice'
import DeleteButton from '../../components/DeleteButton';
import { API_URL } from '../../utils/constants'


const Stockgloboal = () => {


  const { error, loading, data } = useSelector(state => state.products);
  const dispatch = useDispatch();


  // const { fetchCOLORS: fetchData, COLORS, COLORS_error: error, COLORS_loading: loading } = useGlobalContext();
  const NameAlisa = "Produit";
  const NameAlisPlurals = "Produits";
  const add_edit_delete_link = "products" // root

  useEffect(() => {
    dispatch(fetchAllStocks());
  }, [dispatch]);

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <Error />
  }

  const dataSet = data;
  const columns = [
    {
      "data": "product_id",
      "title": "Image",
      "render": function (data) {
        // return '<img width="100" height="100" src="' + data + '"/>';
        return <img width="50" height="50" src={`${API_URL}${data.image}`} />;
      }
    },
    {
      "data": "product_id",
      "title": "SKU",
      "render": function (data) {
        // return '<img width="100" height="100" src="' + data + '"/>';
        return data?.sku;
      }
    },
    {
      "data": "product_id",
      "title": "Titre",
      "width": "20%",
      "render": function (data) {
        // return '<img width="100" height="100" src="' + data + '"/>';
        return data?.name;
      }
    },

    {
      "data": "color_id",
      "title": "Couleur",
      "render": function (data) {
        return <span>{data.name}<button tabIndex="-1" className='color-btn' style={{ background: data.code }}></button></span>

      }
    },
    {
      "data": "size_id",
      "title": "Taille",
      "width": "15%",
      "render": function (data) {
        return <div className='colors'>{data.name}</div>;
      }
    },
    {
      "data": "quantity",
      "title": "Qunatité"
    },
    {
      "data": "updatedAt",
      "title": "Modifié le",
      "render": function (data) {
        if (data) {
          const date = new Date(data);
          const final_date = new Intl.DateTimeFormat('tn-TN', { dateStyle: 'short', timeStyle: 'short' }).format(date);

          return (
            <>

              {
                final_date
              }
            </>
          )
        }
      }
    },
    {
      "data": "product_id",
      "title": "Actions",
      "width": "20%",
      "render": function (data) {
        return (
          <>
            <Link to={`/products/stock/${data._id}`} className='btn btn-success btn-sm'>Modifier</Link>
          </>
        )
        // '<a class="btn btn-success mx-2  btn-sm" href="/products/edit/' + data + '">Modifier</a>'
        //   + '<a class="btn btn-danger btn-sm" href="/products/delete/' + data + '"  onclick="return confirm(\'es-tu sûr?\')">Supprimer</a>'
      }
    },
  ];


  return <Wrapper>
    <main>
      <div className="container-fluid px-4">
        <h1 className="mt-4">gestion des stocks</h1>
        <ol className="breadcrumb mb-4">
          <li className="breadcrumb-item active"></li>
        </ol>


        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Inventaire
            <Link to={`/${add_edit_delete_link}/add/`} className="btn btn-primary btn-sm float-end mx-1">Créer un produit</Link>
          </div>
          <div className="card-body">
            <Table data={dataSet} columns={columns} />
          </div>
        </div>
      </div>
    </main>
  </Wrapper>
}

const Wrapper = styled.section`

img{
  object-fit: cover;
  width: 50px;
  height: 50px;
}
.colors {
    display: flex;
    grid-template-columns: 40px 1fr;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}
.size-btn {
    width: auto;
}

.color-btn {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #222;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--bs-gray);
    cursor: pointer;
    opacity: 0.8;
    align-items: center;
    justify-content: center;
}

`

export default Stockgloboal
