import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateStockData } from '../../store/product_slice';

function StockItem({ color, size, product_id, quantity }) {

    // const { id } = useParams();
    const dispatch = useDispatch();


    const handleInput = (e) => {
        dispatch(updateStockData({ color: color._id, size: size._id, product_id: product_id, data: { [e.target.name]: e.target.value } }))
    };

    return (
        <tr>
            <td>{color.name}<button  tabIndex="-1" className='color-btn' style={{ background: color.code }}></button></td>
            <td>{size.name}</td>
            <td><input type="number" min="0" name="quantity" value={quantity} onChange={handleInput} /></td>
        </tr>
    )
}
export default StockItem