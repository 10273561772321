import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Error from '../../components/Error'
import Loading from '../../components/Loading'

import Table from "../../components/Table";
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllDataWaitingShip, markAllOrdersShipped } from '../../store/order_slice'
import DeleteButton from '../../components/DeleteButton';
import { FaPhoneAlt, FaShippingFast } from 'react-icons/fa';
import { FcApproval, FcShipped } from 'react-icons/fc';
import { toast } from 'react-toastify';


const WaitingShip = () => {


  const [csvData, SetCsvData] = useState([]);
  const { error, loading, success, data } = useSelector(state => state.orders);
  const dispatch = useDispatch();


  // const { fetchCOLORS: fetchData, COLORS, COLORS_error: error, COLORS_loading: loading } = useGlobalContext();
  const NameAlisa = "Commande";
  const NameAlisPlurals = "Commandes Confirmées & En Attente D'Expédition";
  const add_edit_delete_link = "orders" // root

  useEffect(() => {
    dispatch(fetchAllDataWaitingShip());
  }, [dispatch]);


  const markAllShipped = () => {
    dispatch(markAllOrdersShipped());
  }


  function formatData(input) {
    return input.replace('é', 'e')
      .replace('è', 'e')
      .replace('à', 'a')
      .replace(/[^a-zA-Z]/g, " ");
  }

  const downloadCsv = () => {


    let csvContent = csvData.map(e => e.join(";")).join("\n");
    const bom = "\uFEFF"

    var encodedUri = "data:text/csv;charset=utf-8," + escape(csvContent);
    // var encodedUri = "data:text/csv;charset=utf-8,\uFEFF\n" + encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "first.csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".

  }


  useEffect(() => {
    if (success) {
      toast.success("Tous les colis sont marquées comme expédiées", { theme: "colored" });
    }

    if (error) {
      toast.error(error, { theme: "colored" });
    }
  }, [success, error])

  useEffect(() => {

    if (data.length) {
      let tempdata = data?.map((data_row) => {
        return [
          formatData(data_row.customer.name),
          formatData(data_row.customer.address),
          data_row.customer.ville,
          data_row.customer.ville,
          data_row.customer.phone,
          " ",
          1,
          data_row.total,
          data_row?.orderItems.map((p) => `p_${p?.product_id?.sku || p?.name}_c_${p?.color_id?.name}_t_${p?.size_id?.name} X ${p.amount}`).join('|'),
          " "
        ]
      });
      console.log(tempdata);
      // console.log(...csvData, tempdata);
      // SetCsvData([...csvData, tempdata]);
      const headers = [
        "destinataire_nom",
        "adresse",
        "ville",
        "gouvernerat",
        "telephone",
        "telephone2",
        "nombre_de_colis",
        "prix",
        "designation",
        "commentaire"
      ];
      SetCsvData([headers, ...tempdata]);



    }


  }, [data]);


  if (loading) {
    return <Loading />
  }






  const dataSet = data;

  const columns = [
    {
      "data": "customer",
      "title": "Nom du Client ",
      "render": function (data) {
        return (
          <>
            {data.name}
          </>
        )
      }
    },
    {
      "data": "customer",
      "title": "Ville ",
      "render": function (data) {
        return (
          <>
            {data.ville}
          </>
        )
      }
    },
    {
      "data": "customer",
      "title": "Téléphone",
      "render": function (data) {
        return (
          <>
            {data.phone}
          </>
        )
      }
    },
    {
      "data": "customer",
      "title": "Adress",
      "render": function (data) {
        return (
          <>
            {data.address}
          </>
        )
      }
    },
    {
      "data": "total",
      "title": "Total",
      "render": function (data) {
        return (
          <>
            {data.toFixed(3)}
          </>
        )
      }
    },

    {
      "data": "isConfirmed",
      "title": "Confirmé",
      "render": function (data) {
        return (
          <>

            {
              data ? <FcApproval /> : <FaPhoneAlt />
            }
            <span style={{ visibility: 'hidden' }}>{data.toString()}</span>

          </>
        )
      }
    },
    {
      "data": "isShipped",
      "title": "Expedié",
      "render": function (data) {
        return (
          <>

            {
              data ? <FcShipped /> : <FaShippingFast />
            }
            <span style={{ visibility: 'hidden' }}>{data.toString()}</span>

          </>
        )
      }
    },
    {
      "data": "createdAt",
      "title": "Date",
      "render": function (data) {
        const date = new Date(data);
        const final_date = new Intl.DateTimeFormat('tn-TN', { dateStyle: 'short', timeStyle: 'short' }).format(date);

        return (
          <>

            {
              final_date
            }
          </>
        )
      }
    },
    {
      "data": "_id",
      "title": "Actions",
      "render": function (data) {
        return (
          <>
            <Link to={`/${add_edit_delete_link}/edit/${data}`} className='btn btn-success mx-2  btn-sm'>Modifier</Link>
            <DeleteButton to={`/${add_edit_delete_link}/delete/${data}`} />
          </>
        )
      }
    },
  ];


  return <main>
    <div className="container-fluid px-4">
      <h1 className="mt-4">Gestion des Commandes </h1>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item active"></li>
      </ol>


      <div className="card mb-4">
        <div className="card-header">
          <i className="fas fa-table me-1"></i>
          Liste des {NameAlisPlurals}

          {dataSet.length && <button onClick={downloadCsv} className='btn btn-warning btn-sm float-end'>Télécharger CSV</button>}
          {dataSet.length && <button onClick={markAllShipped} className='btn btn-success btn-sm float-end'>Marquez tous expediés </button>}

        </div>
        <div className="card-body">
          <Table data={dataSet} columns={columns} />
        </div>
      </div>
    </div>
  </main>
}

export default WaitingShip
