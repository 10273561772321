import React from "react";
import { Link } from 'react-router-dom'
import { FaHome, FaCubes, FaShoppingBasket, FaPalette, FaBarcode, FaRulerVertical, FaServer, FaUsers } from 'react-icons/fa';

import useWindowDimensions from "../utils/useWindowDimensions";
import { toggleSidebar } from "../store/users_slice";
import { useDispatch } from 'react-redux';

function Sidebar() {

    const { height, width } = useWindowDimensions();
    // const { toggleSidebar } = useUserContext();

    const dispatch = useDispatch();
  
    const checkToggleScreen = () => {
        if (width <= 992) {
            dispatch(toggleSidebar());
        }
    };
    return (
        <div id="layoutSidenav_nav">
            <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                <div className="sb-sidenav-menu">
                    <div className="nav">
                        <Link className="nav-link" to="/" onClick={checkToggleScreen}>
                            <div className="sb-nav-link-icon"><FaHome /></div>
                            Accueil
                        </Link>
                        <Link className="nav-link" to="/products" onClick={checkToggleScreen}>
                            <div className="sb-nav-link-icon"><FaBarcode /></div>
                            Produits
                        </Link>
                        <Link className="nav-link" to="/categories" onClick={checkToggleScreen}>
                            <div className="sb-nav-link-icon"><FaCubes/></div>
                            Categories
                        </Link>
                        <Link className="nav-link" to="/orders" onClick={checkToggleScreen}>
                            <div className="sb-nav-link-icon"><FaShoppingBasket/></div>
                            Commandes
                        </Link>
                        <Link className="nav-link" to="/colors" onClick={checkToggleScreen}>
                            <div className="sb-nav-link-icon"><FaPalette/></div>
                            Couleurs
                        </Link>
                        <Link className="nav-link" to="/sizes" onClick={checkToggleScreen}>
                            <div className="sb-nav-link-icon"><FaRulerVertical/></div>
                            Tailles
                        </Link>
                        <Link className="nav-link" to="/users" onClick={checkToggleScreen}>
                            <div className="sb-nav-link-icon"><FaUsers/></div>
                            Utilisateurs
                        </Link>
                        <Link className="nav-link" to="/settings" onClick={checkToggleScreen}>
                            <div className="sb-nav-link-icon"><FaServer/></div>
                            Paramêtres
                        </Link>
                    </div>
                </div>

            </nav>
        </div>
    )
}

export default Sidebar