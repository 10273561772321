import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Error from '../../components/Error'
import Loading from '../../components/Loading'

import Table from "../../components/Table";
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllData } from '../../store/order_slice'
import DeleteButton from '../../components/DeleteButton';
import { FaPhoneAlt, FaShippingFast } from 'react-icons/fa';
import { FcApproval, FcShipped } from 'react-icons/fc';


const List = () => {


  const { error, loading, data } = useSelector(state => state.orders);
  const dispatch = useDispatch();


  // const { fetchCOLORS: fetchData, COLORS, COLORS_error: error, COLORS_loading: loading } = useGlobalContext();
  const NameAlisa = "Commande";
  const NameAlisPlurals = "Commandes";
  const add_edit_delete_link = "orders" // root

  useEffect(() => {
    dispatch(fetchAllData());
  }, [dispatch]);

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <Error msg={error} />
  }

  const dataSet = data;

  const columns = [
    {
      "data": "customer",
      "title": "Nom du Client ",
      "render": function (data) {
        return (
          <>
            {data.name}
          </>
        )
      }
    },
    {
      "data": "customer",
      "title": "Ville ",
      "render": function (data) {
        return (
          <>
            {data.ville}
          </>
        )
      }
    },
    {
      "data": "customer",
      "title": "Téléphone",
      "render": function (data) {
        return (
          <>
            {data.phone}
          </>
        )
      }
    },
    {
      "data": "customer",
      "title": "Adress",
      "render": function (data) {
        return (
          <>
            {data.address}
          </>
        )
      }
    },
    {
      "data": "total",
      "title": "Total",
      "render": function (data) {
        return (
          <>
            {data.toFixed(3)}
          </>
        )
      }
    },

    {
      "data": "isConfirmed",
      "title": "Confirmé",
      "render": function (data) {
        return (
          <>

            {
              data ? <FcApproval /> : <FaPhoneAlt />
            }
            <span style={{ visibility: 'hidden' }}>{data.toString()}</span>

          </>
        )
      }
    },
    {
      "data": "isShipped",
      "title": "Expedié",
      "render": function (data) {
        return (
          <>

            {
              data ? <FcShipped /> : <FaShippingFast />
            }
            <span style={{ visibility: 'hidden' }}>{data.toString()}</span>

          </>
        )
      }
    },
    {
      "data": "createdAt",
      "title": "Date",
      "render": function (data) {
        const date = new Date(data);
        const final_date = new Intl.DateTimeFormat('tn-TN', { dateStyle: 'short', timeStyle: 'short' }).format(date);

        return (
          <>

            {
              final_date
            }
          </>
        )
      }
    },
    {
      "data": "_id",
      "title": "Actions",
      "render": function (data) {
        return (
          <>
            <Link to={`/${add_edit_delete_link}/edit/${data}`} className='btn btn-success mx-2  btn-sm'>Modifier</Link>
            <DeleteButton to={`/${add_edit_delete_link}/delete/${data}`} />
          </>
        )
      }
    },
  ];


  return <main>
    <div className="container-fluid px-4">
      <h1 className="mt-4">Gestion des {NameAlisPlurals}</h1>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item active"></li>
      </ol>


      <div className="card mb-4">
        <div className="card-header">
          <i className="fas fa-table me-1"></i>
          Liste des {NameAlisPlurals}
          <Link to={`/${add_edit_delete_link}/add/`} className="btn btn-primary btn-sm float-end mx-1 ">Créer</Link>
          <Link to={`/${add_edit_delete_link}/WaitingShip/`} className="btn btn-warning btn-sm float-end mx-1 ">En attente d'expédition</Link>

        </div>
        <div className="card-body">
          <Table data={dataSet} columns={columns} />
        </div>
      </div>
    </div>
  </main>
}

export default List
