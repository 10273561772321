import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import styled from 'styled-components'
import FormMessage from '../../components/FormMessage'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import LoadingButton from '../../components/LoadingButton';

import { useSelector, useDispatch } from 'react-redux';
import { updateItemData, updateTotal, deleteOrderItem } from '../../store/order_slice';
import Loading from '../../components/Loading'
import { FaRegTrashAlt } from 'react-icons/fa'
import { API_URL } from '../../utils/constants'

const EditOrderItem = ({ index, image, price, amount, line_total, name, product_id, size_id, color_id }) => {


  const { loading: prod_loading, error: prod_error, data: prod_data } = useSelector(state => state.products);

  const [selectedProductInfo, setSelectedProductInfoo] = useState();
  const [customPrice, setCustomPrice] = useState(price);
  const dispatch = useDispatch();
  const { settings } = useSelector(state => state.users);

  useEffect(() => {
    if (prod_data.length > 0 && product_id) {

      const res = prod_data.find((p) => p._id === product_id)
      console.log(res);
      if (res._id) {
        setSelectedProductInfoo(res);
        dispatch(updateItemData({ index, data: { price: Number(res.price).toFixed(3), name: res.name, image: res.image } }))
      }
    }
    else {
      setSelectedProductInfoo(false);
      dispatch(updateItemData({ index, data: { price: 0, image: settings?.logo?.url } }))
    }



  }, [product_id, prod_data])


  useEffect(() => {
    dispatch(updateItemData({ index, data: { line_total: amount * price } }))
    dispatch(updateTotal())
  }, [amount, price, product_id])


  const handleInput = (e) => {

    dispatch(updateItemData({ index, data: { [e.target.name]: e.target.value } }))
  };

  const formatPrice = (e) => {
    setCustomPrice(Number(e.target.value).toFixed(3));
  };


  return <Wrapper key={index} >
    <td><img src={`${API_URL}${selectedProductInfo?.image || settings?.logo?.url || image}`} className="img-cart" /></td>
    <td >
      <strong>
        <select className='form-select' value={product_id} name="product_id" onChange={handleInput} required="required" >
          <option value="">Produit ...</option>
          {prod_data.length > 0 && prod_data?.map((prod, index) => {
            return <option key={index} value={prod._id}>{`${prod?.sku || "#"} | ${prod.name}`}</option>
          })}
        </select>

      </strong>
      {/* <p>{name}</p> */}
    </td>
    <td>
      <strong>
        <select className='form-select' value={size_id} name="size_id" onChange={handleInput} required="required" >
          <option value="">Taille..</option>
          {selectedProductInfo && selectedProductInfo.size.map((size, index) => {
            return <option key={index} value={size._id}>{size.name}</option>
          })}
        </select>

      </strong>
      {/* <p>Size : 26</p> */}
    </td>
    <td>
      <strong>
        <select className='form-select' value={color_id} name="color_id" onChange={handleInput} required="required" >
          <option value="">Couleur..</option>

          {selectedProductInfo && selectedProductInfo.colors.map((color, index) => {
            return <option key={index} value={color._id}>{color.name}</option>
          })}
        </select>

      </strong>
      {/* <p>Size : 26</p> */}
    </td>

    <td>
      <div className='form-group mx-sm-3 mb-2'>
        <input className="form-control" type="number" value={amount} name="amount" onChange={handleInput} required="required" />
      </div>
    </td>

    <td>{Number(price).toFixed(3)}</td>
    <td>
      {/* {Number(price * amount).toFixed(3)} */}
      <input className="form-control" type="text" name="line_total" value={Number(line_total).toFixed(3)} readOnly={true} />
    </td>
    <td><FaRegTrashAlt onClick={() => { dispatch(deleteOrderItem({ index })) }} /></td>
  </Wrapper>
}


const Wrapper = styled.tr`
.img-cart {
    display: block;
    max-width: 100px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}
table tr td{
    border:1px solid #FFFFFF;    
}

table tr th {
    background:#eee;    
}
`

export default EditOrderItem
