import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Error from '../../components/Error'
import Loading from '../../components/Loading'
import Table from "../../components/Table";
import { useSelector, useDispatch } from 'react-redux';
import { getUsers } from '../../store/users_slice';
import DeleteButton from '../../components/DeleteButton';


const USERS = () => {

    const { error, loading, user } = useSelector(state => state.users);
    const dispatch = useDispatch();


    // const { getUsers: fetchData, users: USERS, error, loading } = useUserContext();
    const NameAlisa = "Utilisateur";
    const NameAlisPlurals = "Utilisateurs";

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);

    if (loading) {
        return <Loading />
    }
    if (error) {
        return <Error msg={error} />
    }

    const dataSet = user;

    const columns = [
        {
            "data": "name",
            "title": "Nom",
            //   "width": "80%"
        },
        {
            "data": "email",
            "title": "Nom d'utilisateur",
            //   "width": "80%"
        },
        {
            "data": "isAdmin",
            "title": "Admin",
            "render": function (data) {
                return data ? "ADMIN" : "CLIENT"
            }
            //   "width": "80%"
        },
        {
            "data": "_id",
            "title": "Actions",
            "render": function (data) {
                return (
                    <>
                        <Link to={`/USERS/edit/${data}`} className='btn btn-success mx-2  btn-sm'>Modifier</Link>
                        <DeleteButton to={`/USERS/delete/${data}`} />
                    </>
                )
            }
        },
    ];


    return <main>
        <div className="container-fluid px-4">
            <h1 className="mt-4">Gestion des {NameAlisPlurals}</h1>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active"></li>
            </ol>


            <div className="card mb-4">
                <div className="card-header">
                    <i className="fas fa-table me-1"></i>
                    Liste des {NameAlisPlurals}
                    <Link to={`/USERS/add/`} className="btn btn-primary btn-sm float-end">Ajouter</Link>
                </div>
                <div className="card-body">
                    <Table data={dataSet} columns={columns} />
                </div>
            </div>
        </div>
    </main>
}

export default USERS
