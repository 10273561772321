import React, { useEffect, useRef, useState } from "react"
import $ from 'jquery'
import "./css/datatables.min.css";
import { useSelector } from 'react-redux';

// import { useGlobalContext } from "../../context/global_context";


const TblFunc = (props) => {

    // const { isSidebarOpen } = useGlobalContext()
    const { isSidebarOpen } = useSelector(state => state.users);
    const [tableData, setTableData] = useState([]);

    $.DataTable = require('datatables.net')
    const tableRef = useRef()


    const ApplyDataTable = () => {
        const table = $(tableRef.current).DataTable(
            {
                autoWidth: false,
                language: {
                    "search": "Rechercher:",
                    "emptyTable": "Aucune donnée disponible dans le tableau",
                    "paginate": {
                        "first": "Première",
                        "last": "Dernière",
                        "next": "Suivante",
                        "previous": "Précédente"
                    },
                    "sLengthMenu": "Afficher _MENU_ entrées",
                    "sZeroRecords": "Aucune entrée correspondante trouvée",
                    "sInfo": "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                    "sInfoEmpty": "Affichage de 0 à 0 sur 0 entrées",
                    "sInfoFiltered": "(filtrées depuis un total de _MAX_ entrées)",
                },
                order: [],
                // data: props.data,
                // columns: props.columns,
                destroy: true,
                info: props.hasOwnProperty('info') ? props.info : true,
                searching: props.hasOwnProperty('searching') ? props.searching : true,
                bLengthChange: props.hasOwnProperty('bLengthChange') ? props.bLengthChange : true,
                pageLength: props.hasOwnProperty('pageLength') ? props.pageLength : 10,
                lengthMenu: [
                    [10, 25, 50, 100, -1],
                    [10, 25, 50, 100, 'Tous'],
                ],
                // bLengthChange: false
                // info: false
                // I think some clean up is happening here,

            }
        )
        // Extra step to do extra clean-up.
        return function () {
            console.log("Table destroyed")
            table.destroy()
            // table.draw()
            // table.rows()
            //     .invalidate()
            //     .draw();
        }
    }

    useEffect(() => {
        ApplyDataTable()
    }, [tableData])

    useEffect(() => {
        // props.data && setTableData(props.data)
        // console.log(props.data);
        if (props.data) {

            setTableData(props.data)
            $(tableRef.current).DataTable().destroy();

            // const timer = setTimeout(function () {
            //     ApplyDataTable()
            // }, 0);
            // return () => clearTimeout(timer);


        }
    }, [props.data, isSidebarOpen])



    return (
        <div className="table-responsive">
            <table className="display" ref={tableRef} id={props.id || "datatablesSimple"}>
                <thead>
                    <tr>
                        {
                            props?.columns?.map((col, index) => {
                                return <th style={col.width && { width: col.width }} key={index}>{col.title}</th>
                            })
                        }
                    </tr>
                </thead>
                <tfoot>
                    <tr>
                        {
                            props?.columns?.map((col, index) => {
                                return <th key={index}>{col.title}</th>
                            })
                        }
                    </tr>
                </tfoot>
                <tbody>
                    {

                        tableData.length ? tableData?.map((row, index) => {
                            return <tr key={index}>
                                {
                                    props.columns.map((trcol, index2) => {
                                        // if(!trcol?.data?.render)
                                        // console.log(trcol.render);
                                        // console.log(row[trcol.data]);
                                        if (trcol.render) {
                                            return <td key={index2}>{trcol.render(row[trcol.data])}</td>
                                        } else {
                                            return <td key={index2}>{row[trcol.data]}</td>
                                        }
                                        // return <td key={index2}>{row[trcol.data]}</td>
                                        // <td key={index2}></td>
                                    })
                                }

                            </tr>
                        }) : <></>
                    }
                </tbody>
            </table>
        </div>

    )
}
export default TblFunc;
