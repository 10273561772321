import React from 'react'
import { useNavigate } from 'react-router-dom'
const DeleteButton = ({ to }) => {

    const navigate = useNavigate();



    const confirmLink = (e) => {
        if (window.confirm('es-tu sûr?')) {
            navigate(to);
        }
    }

    return (
        <button type='button' className='btn btn-danger btn-sm' onClick={confirmLink}>Supprimer</button>
    )
}

export default DeleteButton
