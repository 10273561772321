
import { configureStore } from '@reduxjs/toolkit'
import categoriesSlice from './category_slice';
import colorsSlice from './colors_slice';
import productsSlice from './product_slice';
import sizesSlice from './size_slice';
import usersSlice from './users_slice';
import ordersSlice from './order_slice';

// const listenerMiddleware = createListenerMiddleware()


const localUserInfo = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;

const initialState = {
    userInfo: localUserInfo
}

const store = configureStore({
    initialState,
    reducer: {
        users: usersSlice.reducer,
        colors: colorsSlice.reducer,
        sizes: sizesSlice.reducer,
        categories: categoriesSlice.reducer,
        products: productsSlice.reducer,
        orders: ordersSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: true,
        }),

});
export default store;
