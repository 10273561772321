import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Multiselect from 'multiselect-react-dropdown';

import FormMessage from '../../components/FormMessage'
import { Link, useNavigate } from 'react-router-dom'
import LoadingButton from '../../components/LoadingButton';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import { add } from '../../store/product_slice';

import { fetchCOLORS } from '../../store/colors_slice';
import { fetchAllData as fetchCatgories } from '../../store/category_slice';
import { fetchAllData as fetchSIZES } from '../../store/size_slice';
import Loading from '../../components/Loading';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { API_URL } from '../../utils/constants';
import stringToBoolean from '../../utils/stringToBoolean';

const Add = () => {

  const { loading, error, success, data: ADD_PRODUCTS } = useSelector(state => state.products);
  const { loading: SIZES_loading, error: SIZES_error, data: SIZES } = useSelector(state => state.sizes);
  const { loading: COLORS_loading, error: COLORS_error, colors: COLORS } = useSelector(state => state.colors);
  const { loading: categories_loading, error: categories_error, data: categories } = useSelector(state => state.categories);

  const dispatch = useDispatch();

  const NameAlisa = "Produit";
  const NameAlisPlurals = "Produits";
  const BackLink = "/products"
  const navigate = useNavigate();

  const emptyData = {
    sku: '',
    name: '',
    color_image_0: "",
    color_image_1: "",
    color_image_2: "",
    color_image_3: "",
    color_image_4: "",
    images: [
      {},
      {},
      {},
      {},
      {}
    ],//upload
    description: "description",
    featured: false,
    stock: "1",
    price: "25",
    size: [],
    category: "",
    colors: [],
    company: "lina shoes",
    message: "",
    msgType: "",
  };

  const [formData, setFormData] = useState(emptyData);
  const [file, setFile] = useState({
    image_1: "",
    image_2: "",
    image_3: "",
    image_4: "",
    image_5: ""
  });
  const [uploadedfile, setUploadedfile] = useState("");
  const [uploadedImages, setUploadedImages] = useState([
    {},
    {},
    {},
    {},
    {},
  ]);





  useEffect(() => {
    dispatch(fetchCOLORS());
    dispatch(fetchSIZES());
    dispatch(fetchCatgories());
  }, [])









  useEffect(() => {
    if (success) {
      if (ADD_PRODUCTS) {
        setFormData(emptyData);
        setFile({})
      }
      toast.success(NameAlisa + " ajoutée avec succès", { theme: "colored" });
      navigate(`${BackLink}/add/`, { replace: true });


    }

    if (error) {
      toast.error(error, { theme: "colored" });
    }


  }, [success, error])


  const addImage = () => {

    let newSet = [...uploadedImages];
    setUploadedImages([...newSet, {}]);
  }


  const deleteImage = (index, tmpfile) => {

    console.log(index);
    console.log(uploadedImages);
    let newSet = [...uploadedImages];
    // newSet[index] = null;
    if (newSet.length > 5)
      newSet.splice(index, 1);
    else newSet.splice(index, 1, {});

    setUploadedImages([...newSet])

    console.log(tmpfile);

    if (file[tmpfile])
      setFile({ ...file, [tmpfile]: null });

    let fdnewSet = [...formData.images];
    fdnewSet.splice(index, 1, "");


    let img_color_to_delete = `color_image_${index}`;
    setFormData({ ...formData, images: fdnewSet, [img_color_to_delete]: "" })
  }


  const handleColorsSelect = (selectedList) => {
    setFormData({ ...formData, colors: selectedList })
  };

  const handleSizesSelect = (selectedList) => {
    setFormData({ ...formData, size: selectedList })
  };

  const handleInput = (e) => {
    const value = (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
    const name = e.target.name;
    if (e.target.files)
      setFile({ ...file, [e.target.name]: e.target.files[0] })

    setFormData({ ...formData, [name]: value })
  };

  const FormHandler = (e) => {
    e.preventDefault();

    // if (FormData.get("images")    ) {
    //   toast.error(error, { theme: "colored" });
    // }
    const fileData = new FormData();


    fileData.append('name', formData.name)
    fileData.append('sku', formData.sku)
    // fileData.append('image', file.image)
    uploadedImages.map((temp, i) => {
      fileData.append(`images`, file[`image_${i + 1}`] || (formData.images[`${i}`] && JSON.stringify(formData.images[`${i}`])))
      fileData.append('color_image', formData[`color_image_${i}`] || false)
    })

    // fileData.append('images', file.image_1 || (formData.images[0] && JSON.stringify(formData.images[0])))
    // fileData.append('images', file.image_2 || (formData.images[1] && JSON.stringify(formData.images[1])))
    // fileData.append('images', file.image_3 || (formData.images[2] && JSON.stringify(formData.images[2])))
    // fileData.append('images', file.image_4 || (formData.images[3] && JSON.stringify(formData.images[3])))
    // fileData.append('images', file.image_5 || (formData.images[4] && JSON.stringify(formData.images[4])))

    // fileData.append('color_image', formData[`color_image_${i}`] || false)

    // fileData.append('color_image', formData.color_image_0 || false)
    // fileData.append('color_image', formData.color_image_1 || false)
    // fileData.append('color_image', formData.color_image_2 || false)
    // fileData.append('color_image', formData.color_image_3 || false)
    // fileData.append('color_image', formData.color_image_4 || false)
    fileData.append('description', formData.description)
    fileData.append('featured', formData.featured)
    fileData.append('stock', formData.stock)
    fileData.append('price', formData.price)
    if (formData.size)
      fileData.append('size', JSON.stringify(formData.size))
    if (formData.category)
      fileData.append('category', formData.category)
    if (formData.colors)
      fileData.append('colors', JSON.stringify(formData.colors))
    fileData.append('company', formData.company)


    //

    dispatch(add(fileData));
  };



  if (SIZES_loading || categories_loading || COLORS_loading) {
    return <Loading />
  }


  if (!SIZES && !COLORS && !categories) {
    return <Loading />
  }


  if (SIZES_error) {
    toast.error(SIZES_error, { theme: "colored" });
    return <FormMessage />
  }
  if (categories_error) {
    toast.error(categories_error, { theme: "colored" });
    return <FormMessage />
  }
  if (COLORS_error) {
    toast.error(COLORS_error, { theme: "colored" });
    return <FormMessage />
  }



  return <Wrapper>
    <div className="container-fluid px-4">
      <h1 className="mt-4">Gestion des {NameAlisPlurals}</h1>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item active"></li>
      </ol>


      <div className="card mb-4">
        <div className="card-header">
          <i className="fas fa-table me-1"></i>
          Nouveau {NameAlisa}
          {ADD_PRODUCTS._id && <Link to={`${BackLink}/edit/${ADD_PRODUCTS._id}`} className="btn btn-primary btn-sm float-end">Modifier ce produit</Link>}
        </div>
        <div className="card-body">

          <FormMessage message={formData.message} type={formData.msgType} />

          <form onSubmit={FormHandler} >
            <div className="row g-3">

              <div className="col-md-3">
                <label htmlFor="sku">SKU</label>
                <input type="text" className="form-control" required="required" id="sku" name="sku" value={formData.sku} onChange={handleInput} placeholder={`Référence`} />
              </div>
              <div className="col-md-6">
                <label htmlFor="name">Nom</label>
                <input type="text" className="form-control" required="required" id="name" name="name" value={formData.name} onChange={handleInput} placeholder={`Entrez le nom du ${NameAlisa}`} />
              </div>
              <div className="col-md-3">
                <label htmlFor="company">Marque</label>
                <input type="text" className="form-control" required="required" id="company" name="company" value={formData.company} onChange={handleInput} placeholder={`Entrez la marque du ${NameAlisa}`} />
              </div>

              <div className="col-md-5">
                <label>Couleurs</label>
                <Multiselect
                  options={COLORS || []}
                  selectedValues={formData.colors}
                  displayValue="name"
                  showCheckbox="true"
                  avoidHighlightFirstOption="false"
                  onSelect={handleColorsSelect}
                  onRemove={handleColorsSelect}
                />
              </div>
              <div className="col-md-5">
                <label>Tailles</label>
                <Multiselect
                  options={SIZES || []}
                  selectedValues={formData.size}
                  displayValue="name"
                  showCheckbox="true"
                  avoidHighlightFirstOption="false"
                  onSelect={handleSizesSelect}
                  onRemove={handleSizesSelect}
                />
              </div>

              <div className='row g-3'>

                {
                  uploadedImages?.map((img, index) => {
                    if (true) {
                      return (
                        // <div key={index} className="col-md-2">
                        //   <img src={img?.url} className="sub-image-product-preview" />
                        //   <FaTrashAlt onClick={() => deleteImage(index)} />
                        // </div>
                        <div className='col-md-2' key={index} >
                          <label htmlFor={`image_${index + 1}`}>Image {index + 1}</label>
                          <input type="file" className="form-control" required={index === 0 ? true : false} id="name" name={`image_${index + 1}`} onChange={handleInput} />

                          <div key={index} className="card">
                            {
                              file[`image_${index + 1}`] ?
                                <img src={URL.createObjectURL(file[`image_${index + 1}`])} className="card-img-top sub-image-product-preview" /> :
                                (
                                  img?.url ?
                                    <img src={`${API_URL}${img?.url}`} className="card-img-top sub-image-product-preview" /> :
                                    <div className="card-img-top sub-image-product-preview"></div>
                                )
                            }
                            <div className='card-body'>
                              <h4 className="card-title">{`Image ${index + 1}`}</h4>
                              <FaTrashAlt onClick={() => deleteImage(index, `image_${index + 1}`)} />
                              {
                                formData?.colors?.length > 0 && (file[`image_${index + 1}`] || img?.url) &&
                                <select className="form-select" aria-label="Default select example" name={`color_image_${index}`} value={formData[`color_image_${index}`]} onChange={handleInput} >
                                  <option value="">Couleur</option>
                                  {formData.colors.map((c, index) => {
                                    return <option key={index} value={c._id}>{c.name}</option>
                                  })}
                                </select>
                              }
                            </div>
                          </div>
                        </div>

                      )
                    }
                  })
                }

                {uploadedImages.length < 30 ?
                  <div div className='col-md-1 add_image'>
                    <button type='button' className='btn btn-warning' onClick={addImage}>< FaPlus /></button>
                  </div>
                  : <></>
                }
              </div>




              <div className="col-md-6">
                <label htmlFor="category">Catégorie</label>
                <select required="required" className="form-select" aria-label="Default select example" name="category" value={formData.category} onChange={handleInput} >
                  <option value=""></option>
                  {
                    categories.map((cat) => {
                      return <option key={cat._id} value={cat._id}>{cat.name}</option>
                    })
                  }
                </select>
              </div>


              <div className="col-md-5" role="group" aria-label="Basic checkbox toggle button group">
                <input className="btn-check" type="checkbox" name="featured" checked={stringToBoolean(formData.featured)} onChange={handleInput} id="featured" />
                <label className="btn btn-outline-primary mt-4" htmlFor="featured">
                  En vedette
                </label>
              </div>

              <div className="col-md-6">
                <label htmlFor="price">Prix</label>
                <input type="number" className="form-control" required="required" id="price" name="price" value={formData.price} onChange={handleInput} placeholder={`Entrez le prix du ${NameAlisa} `} />
              </div>
              {/* <div className="col-md-6">
                <label htmlFor="price">Stock (par couleur/taille)</label>
                <input type="number" className="form-control" required="required" id="stock" name="stock" value={formData.stock} onChange={handleInput} placeholder={`Entrez le stock du ${NameAlisa}`} />
              </div> */}

              <div className="col-md-12">
                <label htmlFor="description">Description</label>
                <textarea type="text" className="form-control" required="required" id="description" name="description" value={formData.description} onChange={handleInput} placeholder={`Entrez le description du ${NameAlisa}`} />
              </div>


              <div className="form-group col-md-12">
                <Link to={BackLink} className='btn btn-space btn-danger'>Retour</Link>
                <button type="submit" className="btn btn-primary">
                  {loading ? <LoadingButton /> : "Ajouter un nouveau produit"}
                </button>


              </div>

            </div>

          </form>
        </div>
      </div>
    </div>
  </Wrapper>
}



const Wrapper = styled.main`
 .product-main-image-preview{
    width: 100%;
    height: 225px;
    object-fit: scale-down;
}

.sub-image-product-preview{
    width: 100%;
    height: 200px;
    object-fit: cover;


}

.add_image {
    color: green;
    font-size: 4rem;
    margin: 3rem;
    padding: 2rem;
    max-width: 100%;
    cursor: pointer;
}
.add_image svg {
    font-size: 2rem;
}
`

export default Add
