import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { COLORS_url } from "../utils/constants";



const colorsSlice = createSlice({
    name: "colors",
    initialState: {
        colors: false,//to store the added & the edited & all 
        loading: false,
        error: false,
        success: false,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = true
            state.error = false
            state.success = false

        },
        setError: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.success = false

        },
        setColorData: (state, action) => {
            state.colors = action.payload.data
            state.loading = false
            state.error = false
            state.success = action.payload.success
        }, 
        setColors: (state, action) => {
            state.colors = action.payload
            state.loading = false
            state.error = false
            state.success = false
        },
        setSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.success = action.payload
        }
    }
})
export default colorsSlice;
// export const colorsActions = colorsSlice.actions; option 1 
export const { setError, setLoading, setColorData, setColors, setSuccess } = colorsSlice.actions;// option 2

export const addCOLORS = data => async  (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        
        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };


        const response = await axios.post(COLORS_url, data, config);
        const COLORS = response.data;

        dispatch(setColorData({ data: COLORS, success: true }));
    }
    catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }
}

export const fetchSingleCOLORS = id => async  (dispatch, getState) => {
    dispatch(setLoading(true));

    try {
        const response = await axios.get(`${COLORS_url}${id}`);
        const COLORS = response.data;
        dispatch(setColorData({ data: COLORS, success: false }));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));

    }

}

export const fetchCOLORS = () => async  (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const response = await axios.get(COLORS_url);
        const COLORS = response.data;
        // console.log(COLORS);

        dispatch(setColors(COLORS));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));

    }

}

export const deleteSingleCOLORS = (id) => async  (dispatch, getState) => {
    dispatch(setLoading(true));

    try {
        
        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const response = await axios.delete(`${COLORS_url}${id}`, config);
        dispatch(setSuccess(true));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));

    }

}


export const editData = (id, data) => async  (dispatch, getState) => {
    dispatch(setLoading())
    try {
        
        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const response = await axios.put(`${COLORS_url}${id}`, data, config);
        const res_data = response.data;

        dispatch(setColorData({ data: res_data, success: true }))
    }
    catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }
}