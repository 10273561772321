
export const API_URL = process.env.NODE_ENV === "development" ? "" : "https://apilinashoes.ig60.com";


export const products_url = `${API_URL}/api/products/`
export const categories_url = `${API_URL}/api/categories/`
export const SIZES_url = `${API_URL}/api/sizes/`
export const COLORS_url = `${API_URL}/api/colors/`
export const USERS_url = `${API_URL}/api/users/`
export const settings_url = `${API_URL}/api/settings/`
export const orders_url = `${API_URL}/api/orders/`


