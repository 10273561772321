import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { API_URL, settings_url } from '../utils/constants';
import { toast } from 'react-toastify';

// import logo from './../../assets/logo.png';
// import { useNavigate } from 'react-router-dom'

import FormMessage from "./../components/FormMessage";
// import { useUserContext } from '../../context/user_context';
import { useSelector, useDispatch } from 'react-redux';
import { getSettings } from '../store/users_slice';
import { FaTrashAlt } from 'react-icons/fa';


const Settings = () => {
    const { userInfo, settings } = useSelector(state => state.users);
    const dispatch = useDispatch();


    const config = {
        headers: {
            Authorization: `Bearer ${userInfo ? userInfo.token : ""}`
        }
    }


    const [formData, setFormData] = useState({
        logo: "",
        image: "",
        name: "",
        fbpixel: "",
        shippingFees: "",
        message: "",
        title: "",
        url: "",
        description: "",
    });
    const [file, setFile] = useState("");
    const [uploadedfile, setUploadedfile] = useState("");
    const [uploadedImage, setUploadedImage] = useState("");


    useEffect(() => {
        setFormData({
            ...formData,
            name: settings.name,
            title: settings.title,
            url: settings.url,
            description: settings.description,
            logo: settings ? settings?.logo : null,
            image: settings ? settings?.image : null,
            fbpixel: settings.fbpixel,
            shippingFees: settings.shippingFees
        })
    }, [settings])
    const handleInput = (e) => {
        // console.log(e.target.files);

        if (e.target.files)
            setFile({ ...file, [e.target.name]: e.target.files[0] })

        setFormData({ ...formData, [e.target.name]: e.target.value })
    };

    const deleteImage = () => {

        setUploadedImage("");
        if (file.image)
            setFile({ ...file, image: null });

        setFormData({ ...formData, image: "" })
    }
    const FormHandler = async (e) => {
        e.preventDefault();


        const fileData = new FormData();

        console.log(file);

        fileData.append('logo', file.logo || (formData.logo && JSON.stringify(formData.logo))) // uploaded logo or previous logo
        fileData.append('image', file.image || (formData.image && JSON.stringify(formData.image))) // uploaded logo or previous logo
        fileData.append('name', formData.name) // other fields
        fileData.append('title', formData.title) // other fields
        fileData.append('description', formData.description) // other fields
        fileData.append('url', formData.url) // other fields
        fileData.append('fbpixel', formData.fbpixel) // other fields
        fileData.append('shippingFees', formData.shippingFees) // other fields


        console.log(settings_url);
        try {
            const res = await axios.post(settings_url, fileData, config, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })


            const filePath = res.data?.logo?.url;
            setUploadedfile({ filePath });

            const imagePath = res.data?.image?.url;
            setUploadedImage({ imagePath });

            console.log(res);
            toast.success(' Votre configuration a été modifié avec succès', { theme: "colored" });
            // setFormData({ ...formData, message: " Votre configuration a été modifié avec succès", msgType: "success" });

            dispatch(getSettings());

        } catch (error) {
            if (error.response.data.message)
                toast.error(error.response.data.message, { theme: "colored" });


            console.log(error);
        }




    };

    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4">Paramêtres</h1>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active"></li>
            </ol>


            <div className="card mb-4">
                <div className="card-header">
                    <i className="fas fa-table me-1"></i>
                    Paramêtres
                </div>
                <div className="card-body">

                    <FormMessage message={formData.message} type={formData.msgType} />
                    <form onSubmit={FormHandler} >
                        <div className="form-group">
                            <label htmlFor="name">Name </label>
                            <input type="text" className="form-control" required="required" id="name" name="name" value={formData.name || ""} onChange={handleInput} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="logo">Logo </label>
                            <input type="file" className="form-control" id="logo" name="logo" onChange={handleInput} />
                            {

                                file?.logo ? <img src={URL.createObjectURL(file?.logo)} className="logo_preview mt-3 img-thumbnail" /> :
                                    (uploadedfile.filePath || settings?.logo?.url) && <img src={`${API_URL}${uploadedfile.filePath || settings?.logo?.url}`} className="logo_preview mt-3 img-thumbnail" />}
                        </div>

                        <div className="form-group">
                            <label htmlFor="image">Image Acceuil</label>
                            <input type="file" className="form-control" id="image" name="image" onChange={handleInput} />
                            {

                                file?.image ? <img src={URL.createObjectURL(file?.image)} className="logo_preview mt-3 img-thumbnail" /> :
                                    (uploadedImage.filePath || formData?.image?.url) && <img src={`${API_URL}${uploadedImage.filePath || formData?.image?.url}`} className="logo_preview mt-3 img-thumbnail" />}

                            {
                                formData.image && <FaTrashAlt onClick={() => deleteImage()} />
                            }
                        </div>

                        <div className="form-group">
                            <label htmlFor="title">Titre (google) </label>
                            <input type="text" className="form-control" required="required" id="title" name="title" value={formData.title || ""} onChange={handleInput} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">Description (google) </label>
                            <input type="text" className="form-control" required="required" id="description" name="description" value={formData.description || ""} onChange={handleInput} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="url">URL (google) </label>
                            <input type="text" className="form-control" required="required" id="url" name="url" value={formData.url || ""} onChange={handleInput} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="shippingFees">Frais de Livraison </label>
                            <input type="number" className="form-control" id="shippingFees" name="shippingFees" value={formData.shippingFees || ""} onChange={handleInput} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="fbpixel">Facebook Pixel </label>
                            <input type="text" className="form-control" id="fbpixel" name="fbpixel" value={formData.fbpixel || ""} onChange={handleInput} />
                        </div>
                        <div className="form-group">
                            <Link to="/" className='btn btn-space btn-danger'>Retour</Link>
                            <button type="submit" className="btn btn-primary">Enregistrer</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default Settings