import React from 'react'
import FormMessage from './FormMessage'
const Error = ({ msg }) => {

    return (
        <div className="alert alert-danger" role="alert">
            {`${msg ? msg : "Il y a eu une erreur"}`}
        </div>
    )
}

export default Error
