import React, { useEffect } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import Loading from '../../components/Loading'
import Error from '../../components/Error'

import { useSelector, useDispatch } from 'react-redux';
import { deleteSingleCOLORS } from '../../store/colors_slice'
import { toast } from 'react-toastify';


const Delete = () => {

    const { id } = useParams();

    const { error, loading, success } = useSelector(state => state.colors);
    const dispatch = useDispatch();

    // const { deleteSingleCOLORS: deleteSingleFunction, delete_single_COLORS_loading: loading, delete_single_COLORS_error: error } = useGlobalContext()
    const BackLink = "/COLORS"


    useEffect(() => {
        dispatch(deleteSingleCOLORS(id))
    }, [id, dispatch])

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <Error msg={error} />
    }

    if (success) {
        toast.success("Couleur supprimé  avec succès", { theme: "colored" });
        return <Navigate replace to={BackLink} />
    }


}
export default Delete;