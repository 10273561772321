import React from 'react'
import Layout from './components/Layout';
import {
  Home,
  Login,
  Users, DeleteUser, AddUser, EditUser,
  Settings,

  Products,
  AddProducts,
  EditProducts,
  DeleteProducts,
  Stock,
  Stockgloboal,

  IndexCategory,
  AddCategory,
  EditCategory,
  DeleteCategory,

  IndexSize,
  AddSize,
  EditSize,
  DeleteSize,

  IndexOrder,
  AddOrder,
  EditOrder,
  DeleteOrder,
  WaitingShip,

  IndexColor,
  AddColor,
  EditColor,
  DeleteColor

} from './pages/'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import PrivateRouter from "./PrivateRouter";
import Error from './components/Error';
function App() {
  return (
    <>
      <Router>
        <Layout>
          <Routes>
            {/*/**PUBLIC   v*/}
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/' element={<PrivateRouter><Home /></PrivateRouter>} />
            <Route exact path='/users' element={<PrivateRouter><Users /></PrivateRouter>} />
            <Route exact path='/users/delete/:id' element={<PrivateRouter><DeleteUser /></PrivateRouter>} />
            <Route exact path='/users/edit/:id' element={<PrivateRouter><EditUser /></PrivateRouter>} />
            <Route exact path='/users/add/' element={<PrivateRouter><AddUser /></PrivateRouter>} />

            <Route exact path='/settings' element={<PrivateRouter><Settings /></PrivateRouter>} />


            <Route exact path='/orders' element={<PrivateRouter><IndexOrder /></PrivateRouter>} />
            <Route exact path='/orders/WaitingShip' element={<PrivateRouter><WaitingShip /></PrivateRouter>} />
            <Route exact path='/orders/add' element={<PrivateRouter><AddOrder /></PrivateRouter>} />
            <Route exact path='/orders/edit/:id' element={<PrivateRouter><EditOrder /></PrivateRouter>} />
            <Route exact path='/orders/delete/:id' element={<PrivateRouter><DeleteOrder /></PrivateRouter>} />

            <Route exact path='/products' element={<PrivateRouter><Products /></PrivateRouter>} />
            <Route exact path='/products/add' element={<PrivateRouter><AddProducts /></PrivateRouter>} />
            <Route exact path='/products/edit/:id' element={<PrivateRouter><EditProducts /></PrivateRouter>} />
            <Route exact path='/products/stockgloboal/' element={<PrivateRouter><Stockgloboal /></PrivateRouter>} />
            <Route exact path='/products/stock/:id' element={<PrivateRouter><Stock /></PrivateRouter>} />
            <Route exact path='/products/delete/:id' element={<PrivateRouter><DeleteProducts /></PrivateRouter>} />

            <Route exact path='/categories' element={<PrivateRouter><IndexCategory /></PrivateRouter>} />
            <Route exact path='/categories/add' element={<PrivateRouter><AddCategory /></PrivateRouter>} />
            <Route exact path='/categories/edit/:id' element={<PrivateRouter><EditCategory /></PrivateRouter>} />
            <Route exact path='/categories/delete/:id' element={<PrivateRouter><DeleteCategory /></PrivateRouter>} />

            <Route exact path='/sizes' element={<PrivateRouter><IndexSize /></PrivateRouter>} />
            <Route exact path='/sizes/add' element={<PrivateRouter><AddSize /></PrivateRouter>} />
            <Route exact path='/sizes/edit/:id' element={<PrivateRouter><EditSize /></PrivateRouter>} />
            <Route exact path='/sizes/delete/:id' element={<PrivateRouter><DeleteSize /></PrivateRouter>} />

            <Route exact path='/Colors' element={<PrivateRouter><IndexColor /></PrivateRouter>} />
            <Route exact path='/Colors/add' element={<PrivateRouter><AddColor /></PrivateRouter>} />
            <Route exact path='/Colors/edit/:id' element={<PrivateRouter><EditColor /></PrivateRouter>} />
            <Route exact path='/Colors/delete/:id' element={<PrivateRouter><DeleteColor /></PrivateRouter>} />

            <Route path="/*" element={<Error />} />
          </Routes>
        </Layout>
      </Router>
    </>
  );
}

export default App;
