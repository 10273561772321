import React from 'react'

const LoadingButton = ({ content }) => {

    return (<>
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {content || "Chargement..."}
    </>
    );
}

export default LoadingButton;