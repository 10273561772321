import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Loading from '../../components/Loading';
import LoadingButton from '../../components/LoadingButton';
import { editStockData, fetchSingleData } from '../../store/product_slice';
import { API_URL } from '../../utils/constants';
import { formatPrice } from '../../utils/helpers';
import StockItem from './StockItem';

function Stock() {

    const { id } = useParams();
    const dispatch = useDispatch();

    const { loading, error, data, success } = useSelector(state => state.products);



    useEffect(() => {
        dispatch(fetchSingleData(id));
    }, [id])



    useEffect(() => {
        if (success) {
            toast.success("Le stock a été modifié avec succès", { theme: "colored" });
        }
        if (error) {
            toast.error(error, { theme: "colored" });
        }
    }, [success, error])

    const FormHandler = (e) => {
        e.preventDefault();
        dispatch(editStockData(id, data));
    };

    if (loading) {
        return <Loading />
    }
    return (
        <Wrapper>
            <div className="container-fluid px-4">
                <h1 className="mt-4">Gestion du Stock</h1>
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active"></li>
                </ol>


                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Inventaire
                    </div>
                    <div className="card-body">

                        <form onSubmit={FormHandler}>
                            <div className="row g-3">
                                <div className="col-md-2">
                                    {data.image && <img src={`${API_URL}${data.image}`} className=' product-main-image-preview' />}
                                </div>
                                <div className="col-md-10 info">
                                    <h2>Produit : #{data?.sku || ""} | {data.name}</h2>
                                    <h4>Marque : {data.company}</h4>
                                    <h4>Prix : {formatPrice(data.price)}</h4>
                                </div>

                            </div>
                            <div className="table-responsive mt-3">
                                <table className="table">
                                    <thead>
                                        <tr >
                                            <th className='col-2'>Couleur</th>
                                            <th className='col-2'>Taille</th>
                                            <th className='col-1' >Quantité</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            data?.colors?.map((color, index) => {
                                                return data?.size?.map((size_data, index2) => {
                                                    let sItem = data?.stockItems?.find((si) => si._id === (id + color._id + size_data._id));

                                                    return (

                                                        <StockItem key={`${index}_${index2}`} color={color} size={size_data} product_id={id} quantity={sItem?.quantity || 0} />
                                                    )
                                                })
                                            })
                                        }




                                    </tbody>
                                </table>
                            </div>
                            <div className="form-group">
                                <Link to={`/products`} className='btn btn-space btn-danger'>Liste des produits</Link>
                                <Link to={`/products/stockgloboal/`} className='btn btn-space btn-warning'>Stock Global</Link>
                                <button type="submit" className="btn btn-primary">
                                    {loading ? <LoadingButton /> : "Enregistrer"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Wrapper>

    )
}

const Wrapper = styled.section`
 .product-main-image-preview{
     width: 100%;
    height: 225px;
    object-fit: scale-down;
}
.info{
    color: gray;
}
.color-btn {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #222;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--bs-gray);
    cursor: pointer;
    opacity: 0.8;
    align-items: center;
    justify-content: center;
}
`
export default Stock