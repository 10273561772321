import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery'
import "../components/Table/css/datatables.min.css";

import { FaEye, FaBuffer, FaChartBar, FaChartPie, FaPhoneAlt, FaShippingFast, FaShoppingBasket, FaStore, FaUser } from 'react-icons/fa';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStats as get_product_stats } from '../store/product_slice';
import { fetchStats as get_order_stats } from '../store/order_slice';
import { formatDecimal, formatPrice } from '../utils/helpers';
import BarChart from '../components/Chart/BarChart';
import LineChart from '../components/Chart/LineChart';
import PieChart from '../components/Chart/PieChart';
import TopProducts from '../components/TopProducts';
import TopColors from '../components/TopColors';
import TopSizes from '../components/TopSizes';

const Home = () => {


    const { stats: product_stats } = useSelector(state => state.products);
    const { stats: orders_stats } = useSelector(state => state.orders);

    const [salesData, setSalesData] = useState();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(get_product_stats());
        dispatch(get_order_stats());
    }, [])

    useEffect(() => {
        console.log(orders_stats);
        setSalesData(orders_stats.sales_history_chart)
    }, [orders_stats])

    return <>
        <Wrapper>
            <div className="container-fluid px-4">
                <h1 className="mt-4">TABLEAU DE BORD</h1>
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active"></li>
                </ol>

                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Statistiques
                    </div>
                    <div className="card-body">
                        <div className="container-fluid">
                            <section>


                                <div className="row">
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="card card-stats mb-4 mb-xl-0">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <h5 className="card-title text-uppercase text-muted mb-0">Total des ventes</h5>
                                                        <span className="h2 font-weight-bold mb-0">{formatPrice((orders_stats.sum_sales || 0))}</span>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="icon icon-shape  text-white rounded-circle shadow">
                                                            <FaChartBar className="far fa-comment-alt text-danger fa-3x" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="mt-3 mb-0 text-muted text-sm">
                                                    <span className="text-success mr-2">
                                                        <FaBuffer className="fa fa-arrow-up" /> {orders_stats.agg_sum_units}</span>
                                                    <span className="text-nowrap"> Unités </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="card card-stats mb-4 mb-xl-0">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <h5 className="card-title text-uppercase text-muted mb-0">Ventes d'aujourd'hui</h5>
                                                        <span className="h2 font-weight-bold mb-0">{formatPrice(orders_stats.sum_sales_today || 0)}</span>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="icon icon-shape  text-white rounded-circle shadow">
                                                            <FaChartPie className="far fa-comment-alt text-success  fa-3x" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="mt-3 mb-0 text-muted text-sm">
                                                    <span className="text-danger mr-2"><FaBuffer className="fas fa-arrow-down" /> {orders_stats.agg_sum_units_today}</span>
                                                    <span className="text-nowrap">Unités</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="card card-stats mb-4 mb-xl-0">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <h5 className="card-title text-uppercase text-muted mb-0">nombre de commandes</h5>
                                                        <span className="h2 font-weight-bold mb-0">{orders_stats.count || 0}</span>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="icon icon-shape  text-white rounded-circle shadow">
                                                            <FaShoppingBasket className="far fa-comment-alt text-warning fa-3x" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="mt-3 mb-0 text-muted text-sm">
                                                    <span className="text-primary mr-2">
                                                        <FaEye className="fa fa-arrow-up" /> {orders_stats.nb_visitors_all}</span>
                                                    <span className="text-nowrap"> Visiteurs </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="card card-stats mb-4 mb-xl-0">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <h5 className="card-title text-uppercase text-muted mb-0">nombre de commandes aujourd'hui</h5>
                                                        <span className="h2 font-weight-bold mb-0">{orders_stats.nb_orders_today || 0}</span>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="icon icon-shape  text-white rounded-circle shadow">
                                                            <FaShoppingBasket className="far fa-comment-alt text-info fa-3x" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="mt-3 mb-0 text-muted text-sm">
                                                    <span className="text-primary mr-2">
                                                        <FaEye className="fa fa-arrow-up" /> {orders_stats.nb_visitors_today}</span>
                                                    <span className="text-nowrap"> Visiteurs </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row mt-3" >
                                    <div className="col-xl-3 col-sm-6 col-12 mb-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between px-md-1">
                                                    <div className="align-self-center">
                                                        <FaStore className='fas fa-pencil-alt text-info fa-3x' />
                                                    </div>
                                                    <div className="text-end">
                                                        <h3>{product_stats.count}</h3>
                                                        <p className="mb-0">Produits</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-sm-6 col-12 mb-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between px-md-1">

                                                    <div className="align-self-center">
                                                        <FaUser className="far fa-user text-success fa-3x" />
                                                    </div>
                                                    <div className="text-end">
                                                        <h3 className="text-success">{orders_stats.customers}</h3>
                                                        <p className="mb-0">Nombre de clients</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-sm-6 col-12 mb-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between px-md-1">
                                                    <div className="align-self-center">
                                                        <FaPhoneAlt className="fas fa-chart-line text-danger fa-3x" />
                                                    </div>
                                                    <div className="text-end">
                                                        <h3>{orders_stats.not_confirmed}</h3>
                                                        <p className="mb-0">En attente de confirmation</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-sm-6 col-12 mb-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between px-md-1">
                                                    <div className="align-self-center">
                                                        <FaShippingFast className="fas fa-chart-line text-danger fa-3x" />
                                                    </div>
                                                    <div className="text-end">
                                                        <h3>{orders_stats.not_shipped}</h3>
                                                        <p className="mb-0">En attente d'expédition</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </section>
                        </div>
                    </div>
                </div>



                <div className="card mb-4">
                    <div className="card-header">
                        <i className="fas fa-table me-1"></i>
                        Graphique analytique
                    </div>
                    <div className="card-body">
                        <div className="container-fluid">
                            <section>


                                <div className="row">
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="card card-stats mb-4 mb-xl-0">
                                            <div className="card-body">
                                                <BarChart chartdata={salesData} id="_id" value="sum_sales" title="Historique des ventes (30 Jours)" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="card card-stats mb-4 mb-xl-0">
                                            <div className="card-body">
                                                <BarChart chartdata={salesData} id="_id" value="count" title="Historique des commandes (30 Jours)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="card mb-12 col-xl-12 col-lg-12">
                        <div className="card-header">
                            <i className="fas fa-table me-1"></i>
                            Les meilleurs Articles
                        </div>
                        <div className="card-body">
                            <div className="">
                                <section>
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12">
                                            <div className="card card-stats mb-4 mb-xl-0">
                                                <div className="card-body">
                                                    <TopProducts data={orders_stats.sales_history_by_products} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className="card mb-6 col-xl-6 col-lg-6">
                        <div className="card-header">
                            <i className="fas fa-table me-1"></i>
                            Les meilleurs Couleurs
                        </div>
                        <div className="card-body">
                            <div className="">
                                <section>
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12">
                                            <div className="card card-stats mb-4 mb-xl-0">
                                                <div className="card-body">
                                                    <TopColors data={orders_stats.sales_history_by_color} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-6 col-xl-6 col-lg-6">
                        <div className="card-header">
                            <i className="fas fa-table me-1"></i>
                            Les meilleurs Tailles
                        </div>
                        <div className="card-body">
                            <div className="">
                                <section>
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12">
                                            <div className="card card-stats mb-4 mb-xl-0">
                                                <div className="card-body">
                                                    <TopSizes data={orders_stats.sales_history_by_size} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                </div>


                {/* <div className="row">
                    <div className="col-xl-8 col-lg-6">
                        <BarChart chartdata={userData} />
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <PieChart chartdata={userData} />
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <LineChart chartdata={userData} />
                    </div>

                </div> */}
            </div>
        </Wrapper>
    </>
}

export default Home


const Wrapper = styled.main`
.fa-3x {
    font-size: 3em;
}

.card {
    border: 0;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 7%), 0 4px 6px -2px rgb(0 0 0 / 5%);
}


.icon-shape {
    display: inline-flex;
    padding: 12px;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.icon-shape i {
    font-size: 1.25rem;
}

.bg-yellow {
    background-color: #ffd600 !important;
}

.text-nowrap {
    white-space: nowrap !important;
}
h5, .h5 {
    font-size: .8125rem;
}
.text-sm {
    font-size: .875rem !important;
}
.mr-2 {
    margin-right: 0.5rem !important;
}
`