import React, { useEffect, useState } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormMessage = ({ type, message }) => {
    return <></>
    // return <ToastContainer
    //     position="top-right"
    //     autoClose={5000}
    //     hideProgressBar={false}
    //     newestOnTop={false}
    //     closeOnClick
    //     rtl={false}
    //     pauseOnFocusLoss
    //     draggable
    //     pauseOnHover
    // />
    // const [show, setShow] = useState(true);
    // const [msg, setMsg] = useState(message);


    // useEffect(() => {
    //     setMsg(message);
    //     const timeout = setTimeout(() => {
    //         setShow(false)
    //         message = "";
    //     }
    //         , 3000);
    //     return () => {
    //         // just to clear the timeout when component unmounts
    //         clearTimeout(timeout);
    //     };
    // }, [message])

    // if (type === "error" && msg && show) {
    //     return (
    //         <div className="alert alert-danger" role="alert">
    //             {msg}
    //         </div>
    //     )
    // }

    // if (type === "success" && msg && show) {
    //     return (
    //         <div className="alert alert-success" role="alert">
    //             {msg}
    //         </div>
    //     )
    // }








}
export default FormMessage;