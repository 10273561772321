import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown';

import styled from 'styled-components'
import FormMessage from '../../components/FormMessage'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import LoadingButton from '../../components/LoadingButton';

import { useSelector, useDispatch } from 'react-redux';
import { fetchSingleData, editData } from '../../store/product_slice';

import { fetchCOLORS } from '../../store/colors_slice';
import { fetchAllData as fetchCatgories } from '../../store/category_slice';
import { fetchAllData as fetchSIZES } from '../../store/size_slice';
import { FaPlus, FaTrashAlt } from "react-icons/fa";

import Loading from '../../components/Loading'

import stringToBoolean from '../../utils/stringToBoolean'
import { API_URL } from '../../utils/constants';

import { LazyLoadImage } from 'react-lazy-load-image-component';

const Edit = () => {


  const { loading, error, data, success } = useSelector(state => state.products);
  const { loading: SIZES_loading, error: SIZES_error, data: SIZES } = useSelector(state => state.sizes);
  const { loading: COLORS_loading, error: COLORS_error, colors: COLORS } = useSelector(state => state.colors);
  const { loading: categories_loading, error: categories_error, data: categories } = useSelector(state => state.categories);


  const dispatch = useDispatch();
  const history = useNavigate();


  const { id } = useParams();
  const NameAlisa = "Produit";
  const NameAlisPlurals = "Produits";
  const BackLink = "/products"


  const [formData, setFormData] = useState({
    sku: '',
    name: '',
    image: "",//upload
    images: "",//upload
    description: "",
    featured: false,
    stock: "",
    price: "",
    size: "",
    category: "",
    colors: [],
    company: "",
    message: "",
    msgType: "",
    // createdAt: "2022-05-26T09:49:40.366Z",
    // reviews: 10,
    // stars: 5,
    // updatedAt: "2022-05-26T09:49:40.366Z",
    // __v: 0,
    // _id: "628f4d34fffcb9e86d10ff9b",
  });

  const [file, setFile] = useState({
    image_1: "",
    image_2: "",
    image_3: "",
    image_4: "",
    image_5: ""
  });

  // const [uploadedfile, setUploadedfile] = useState("");
  const [uploadedImages, setUploadedImages] = useState([
    {},
    {},
    {},
    {},
    {},
  ]);




  const deleteImage = (index, tmpfile) => {

    // console.log(index);
    // console.log(uploadedImages);
    let newSet = [...uploadedImages];
    // newSet[index] = null;
    // if (newSet.length > 5)
    //   newSet.splice(index, 1);
    // else 
    newSet.splice(index, 1, {});

    setUploadedImages([...newSet])

    // console.log(tmpfile);

    if (file[tmpfile])
      setFile({ ...file, [tmpfile]: null });

    let fdnewSet = [...formData.images];
    fdnewSet.splice(index, 1, null);

    let img_color_to_delete = `color_image_${index}`;


    setFormData({ ...formData, images: fdnewSet, [img_color_to_delete]: false })
  }


  useEffect(() => {
    dispatch(fetchCOLORS());
  }, [])

  useEffect(() => {
    dispatch(fetchSIZES());
  }, [])

  useEffect(() => {
    dispatch(fetchCatgories());
  }, [])


  useEffect(() => {
    dispatch(fetchSingleData(id));
  }, [id])


  useEffect(() => {

    let copy_data = { ...data };
    let img_color_tag_data = data?.images?.map((im, index) => {
      let img_color_tag = `color_image_${index}`
      // console.log(im?.color_id);
      copy_data[img_color_tag] = im?.color_id || "";

    })

    setFormData({ ...copy_data });


    if (data.images) {

      // console.log(data.images.length);
      setUploadedImages([{}, {}, {}, {}, {}])
      setFile([{}, {}, {}, {}, {}])
      let tmpuploadedImages = [];
      const nonEmptyImgs = data.images.filter(n => n);

      // console.log(nonEmptyImgs.length);

      nonEmptyImgs.map((im, index) => {
        tmpuploadedImages[index] = im;
      })
      // console.log(tmpuploadedImages.length);

      setUploadedImages([...tmpuploadedImages]);

    }
  }, [data])

  const addImage = () => {
    let newSet = [...uploadedImages];
    setUploadedImages([...newSet, {}]);
  }

  const handleColorsSelect = (selectedList) => {
    setFormData({ ...formData, colors: selectedList })
  };

  const handleSizesSelect = (selectedList) => {
    setFormData({ ...formData, size: selectedList })
  };

  const handleInput = (e) => {
    const value = (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
    const name = e.target.name;

    if (e.target.files) {
      setFile({ ...file, [e.target.name]: e.target.files[0] })
      // console.log(URL.createObjectURL(e.target.files[0]));
      // console.log(e.target);
      // console.log(uploadedImages);
    }


    setFormData({ ...formData, [name]: value })
  };

  const FormHandler = (e) => {
    e.preventDefault();

    const fileData = new FormData();

    // console.log(formData);

    fileData.append('name', formData.name)
    fileData.append('sku', formData.sku)
    // fileData.append('image', file.image || formData.image)
    // fileData.append('images', file.image_2 || (formData.images[0] && JSON.stringify(formData.images[0])))
    // fileData.append('images', file.image_3 || (formData.images[1] && JSON.stringify(formData.images[1])))
    // fileData.append('images', file.image_4 || (formData.images[2] && JSON.stringify(formData.images[2])))
    // fileData.append('images', file.image_5 || (formData.images[3] && JSON.stringify(formData.images[3])))


    uploadedImages.map((temp, i) => {
      fileData.append(`images_${i + 1}`, file[`image_${i + 1}`] || (formData.images[`${i}`] && JSON.stringify(formData.images[`${i}`])))
      fileData.append('color_image', formData[`color_image_${i}`] || false)
    })


    // for (let i = 0; i < 5; i++)
    //   fileData.append(`images_${i + 1}`, file[`image_${i + 1}`] || (formData.images[`${i}`] && JSON.stringify(formData.images[`${i}`])))


    // for (let i = 0; i < 5; i++)
    //   fileData.append('color_image', formData[`color_image_${i}`] || false)
    // fileData.append('color_image', formData.color_image_1 || false)
    // fileData.append('color_image', formData.color_image_2 || false)
    // fileData.append('color_image', formData.color_image_3 || false)
    // fileData.append('color_image', formData.color_image_4 || false)
    fileData.append('description', formData.description)
    fileData.append('featured', formData.featured)
    fileData.append('stock', formData.stock)
    fileData.append('price', formData.price)
    if (formData.size)
      fileData.append('size', JSON.stringify(formData.size))
    if (formData.category)
      fileData.append('category', formData.category)
    if (formData.colors)
      fileData.append('colors', JSON.stringify(formData.colors))
    fileData.append('company', formData.company)



    // console.log(formData);
    dispatch(editData(id, fileData));

  };

  // useEffect(() => {
  //   if (!loading && !error) {
  //     setFormData({ ...formData, ...data });

  //     if (data.image) {
  //       setUploadedfile(data.image)
  //     }
  //     if (data.images) {
  //       setUploadedImages(data.images)
  //     }

  //     if (data.category) {
  //       const selectedCategory = data.category;
  //       setFormData({ ...formData, category: selectedCategory._id });

  //     }
  //     if (data.size) {
  //       const selectedSizes = data.size;
  //       setFormData({ ...formData, size: selectedSizes });
  //     }
  //     if (data.name) {
  //       setFormData({ ...formData, name: data.name });
  //     }
  //     if (data.company) {
  //       setFormData({ ...formData, company: data.company });
  //     }

  //     console.log(data);
  //     console.log(formData);

  //   }
  // }, [data])



  useEffect(() => {
    if (success) {
      toast.success(NameAlisa + " modifié avec succès", { theme: "colored" });
      // setFile("")
      // setFormData("");
      // setUploadedImages("");
      // history(`${BackLink}/edit/${id}`, { replace: true })
    }

    if (error) {
      toast.error(error, { theme: "colored" });
    }

    // if (data.image) {
    //   setUploadedfile(data.image)
    // }
    // if (data.images) {
    //   // setUploadedImages(data.images)

    //   let tmpuploadedImages = [...uploadedImages];
    //   const nonEmptyImgs = data.images.filter(n => n);
    //   nonEmptyImgs.map((im, index) => {
    //     console.log(index);
    //     console.log(im);
    //     tmpuploadedImages[index] = im;
    //   })
    //   setUploadedImages([...tmpuploadedImages])


    // }
  }, [success, error])




  if (SIZES_loading || categories_loading || COLORS_loading) {
    return <Loading />
  }


  if (!SIZES && !COLORS && !categories) {
    return <Loading />
  }


  if (SIZES_error) {
    toast.error(SIZES_error, { theme: "colored" });
    // return <FormMessage />
  }
  if (categories_error) {
    toast.error(categories_error, { theme: "colored" });
    // return <FormMessage />
  }
  if (COLORS_error) {
    toast.error(COLORS_error, { theme: "colored" });
    // return <FormMessage />
  }


  if (loading) {
    return <Loading />
  }
  // if (error) {
  //   return <Error />

  // }




  return <Wrapper>
    <div className="container-fluid px-4">
      <h1 className="mt-4">Gestion des {NameAlisPlurals}</h1>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item active"></li>
      </ol>


      <div className="card mb-4">
        <div className="card-header">
          <i className="fas fa-table me-1"></i>
          Modification du {NameAlisa}
        </div>
        <div className="card-body">

          {/* <FormMessage message={formData.message} type={formData.msgType} /> */}

          <form onSubmit={FormHandler} >
            <div className="row g-3">

              <div className="col-md-3">
                <label htmlFor="sku">SKU</label>
                <input type="text" className="form-control" required="required" id="sku" name="sku" value={formData.sku || ""} onChange={handleInput} placeholder={`Référence`} />
              </div>
              <div className="col-md-6">
                <label htmlFor="name">Nom</label>
                <input type="text" className="form-control" required="required" id="name" name="name" value={formData.name || ""} onChange={handleInput} placeholder={`Entrez le nom du ${NameAlisa}`} />
              </div>
              <div className="col-md-3">
                <label htmlFor="company">Marque</label>
                <input type="text" className="form-control" required="required" id="company" name="company" value={formData.company || ""} onChange={handleInput} placeholder={`Entrez la marque du ${NameAlisa}`} />
              </div>

              <div className="col-md-5">
                <label>Couleurs</label>
                <Multiselect
                  options={COLORS || []}
                  selectedValues={formData.colors || []}
                  displayValue="name"
                  showCheckbox="true"
                  avoidHighlightFirstOption="false"
                  onSelect={handleColorsSelect}
                  onRemove={handleColorsSelect}
                />
              </div>
              <div className="col-md-5">
                <label>Tailles</label>
                <Multiselect
                  options={SIZES || []}
                  selectedValues={formData.size || []}
                  displayValue="name"
                  showCheckbox="true"
                  avoidHighlightFirstOption="false"
                  onSelect={handleSizesSelect}
                  onRemove={handleSizesSelect}
                />
              </div>

              <div className='row g-3'>
                {/* <div className="col-md-4">

                  <div className="card">
                    {uploadedfile && <img src={uploadedfile} className="card-img-top product-main-image-preview" />}
                    <div className='card-body'>
                      <h4 className="card-title">Image Principale</h4>
                    </div>
                  </div>
                </div> */}
                {
                  uploadedImages?.map((img, index) => {
                    // if (img?.url) {
                    if (true) {
                      return (
                        // <div key={index} className="col-md-2">
                        //   <img src={img?.url} className="sub-image-product-preview" />
                        //   <FaTrashAlt onClick={() => deleteImage(index)} />
                        // </div>
                        <div className='col-md-2' key={index} >
                          <label htmlFor={`image_${index + 1}`}>Image {index + 1}</label>
                          <input type="file" className="form-control" id="name" name={`image_${index + 1}`} onChange={handleInput} />

                          <div key={index} className="card">
                            {
                              file[`image_${index + 1}`] ?
                                <img src={URL.createObjectURL(file[`image_${index + 1}`])} className="card-img-top sub-image-product-preview" /> :
                                (
                                  img?.url ?
                                    <LazyLoadImage
                                      placeholderSrc={`${API_URL}${img?.small}`}
                                      alt={img.filename}
                                      src={`${API_URL}${img?.url}`} // use normal <img> attributes as props
                                      className="card-img-top sub-image-product-preview" /> :

                                    // <img src={`${API_URL}${img?.small}`} className="card-img-top sub-image-product-preview" /> :
                                    <div className="card-img-top sub-image-product-preview"></div>
                                )
                            }


                            <div className='card-body'>
                              <h4 className="card-title">{`Image ${index + 1}`}</h4>
                              <FaTrashAlt onClick={() => deleteImage(index, `image_${index + 1}`)} />

                              {
                                formData?.colors?.length > 0 && (file[`image_${index + 1}`] || img?.url) &&
                                <select className="form-select" aria-label="Default select example" name={`color_image_${index}`} value={formData[`color_image_${index}`]} onChange={handleInput} >
                                  <option value="">Couleur</option>
                                  {formData.colors.map((c, index) => {
                                    return <option key={index} value={c._id}>{c.name}</option>
                                  })}
                                </select>
                              }

                            </div>
                          </div>
                        </div>

                      )
                    }
                  })
                }
                {uploadedImages.length < 30 &&
                  <div className='col-md-1 add_image'>
                    <button type='button' className='btn btn-warning' onClick={addImage}>< FaPlus /></button>
                  </div>

                }
              </div>



              <div className="col-md-6">
                <label htmlFor="category">Catégorie</label>

                <select className="form-select" aria-label="Default select example" name="category" value={formData?.category || ""} onChange={handleInput} >
                  <option value=""></option>
                  {
                    categories?.map((cat) => {
                      return <option key={cat._id} value={cat._id}>{cat.name}</option>
                    })
                  }
                </select>
              </div>


              <div className="col-md-5" role="group" aria-label="Basic checkbox toggle button group">
                <input className="btn-check" type="checkbox" name="featured" checked={stringToBoolean(formData.featured)} onChange={handleInput} id="featured" />
                <label className="btn btn-outline-primary mt-4" htmlFor="featured">
                  En vedette
                </label>
              </div>

              <div className="col-md-6">
                <label htmlFor="price">Prix</label>
                <input type="number" className="form-control" required="required" id="price" name="price" value={formData.price || ""} onChange={handleInput} placeholder={`Entrez le prix du ${NameAlisa}`} />
              </div>
              {/* <div className="col-md-6">
                <label htmlFor="price">Stock</label>
                <input type="number" className="form-control" required="required" id="stock" name="stock" value={formData.stock || ""} onChange={handleInput} placeholder={`Entrez le stock du ${NameAlisa}`} />
              </div> */}

              <div className="col-md-12">
                <label htmlFor="description">Description</label>
                <textarea type="text" className="form-control" required="required" id="description" name="description" value={formData.description || ""} onChange={handleInput} placeholder={`Entrez le description du ${NameAlisa}`} />
              </div>


              <div className="form-group col-md-12">
                <Link to={BackLink} className='btn btn-space btn-danger'>Retour</Link>
                <Link to={`${BackLink}/stock/${id}`} className='btn btn-space btn-warning'>Stock</Link>
                <button type="submit" className="btn btn-primary">

                  {loading ? <LoadingButton /> : "Enregistrer"}

                </button>
              </div>

            </div>

          </form>
        </div>
      </div>
    </div >
  </Wrapper >
}



const Wrapper = styled.main`
 .product-main-image-preview{
     width: 100%;
    height: 225px;
    object-fit: scale-down;

}

.sub-image-product-preview{
    width: 100%;
    height: 200px;
    object-fit: cover;


}
.add_image {
    color: green;
    font-size: 4rem;
    margin: 3rem;
    padding: 2rem;
    max-width: 100%;
    cursor: pointer;
}
.add_image svg {
    font-size: 2rem;
}
`
export default Edit
