import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import FormMessage from '../../components/FormMessage'
import { Link } from 'react-router-dom'
import LoadingButton from '../../components/LoadingButton';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { addUSERS } from '../../store/users_slice';

const Add = () => {


    const NameAlisa = "Utilisatueur";
    const NameAlisPlurals = "Utilisatueurs";
    const BackLink = "/USERS"

    // const {
    //     ADD_USERS,
    //     ADD_USERS_error,
    //     ADD_USERS_loading,
    //     addUSERS: addData,
    // Reset_ADD_USER_State: ResetState } = useUserContext();

    const { error, loading, success, user } = useSelector(state => state.users);
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        name: '',
        email: "",
        password: "",
        isAdmin: false,
        message: "",
        msgType: "",
    });

    useEffect(() => {
        if (success) {
            console.log(user);
            // setFormData({ ...formData, message: NameAlisa + " ajoutée avec succès", msgType: "success" });
            toast.success(NameAlisa + " ajoutée avec succès", { theme: "colored" });
            // ResetState();

        }

        if (error) {
            // setFormData({ ...formData, message: user_error, msgType: "error" });
            toast.error(error, { theme: "colored" });

        }
    }, [user, error])



    const handleInput = (e) => {

        const value = (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
        const name = e.target.name;
        setFormData({ ...formData, [name]: value })
    };

    const FormHandler = (e) => {
        e.preventDefault();
        const data = {
            name: formData.name,
            email: formData.email,
            password: formData.password,
            isAdmin: formData.isAdmin,
        }
        dispatch(addUSERS(data));

    };







    return <Wrapper>
        <div className="container-fluid px-4">
            <h1 className="mt-4">Gestion des {NameAlisPlurals}</h1>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active"></li>
            </ol>


            <div className="card mb-4">
                <div className="card-header">
                    <i className="fas fa-table me-1"></i>
                    Nouveau {NameAlisa}
                </div>
                <div className="card-body">

                    <FormMessage message={formData.message} type={formData.msgType} />

                    <form onSubmit={FormHandler} >
                        <div className="form-group">
                            <label htmlFor="name">Nom</label>
                            <input type="text" className="form-control" required="required" id="name" name="name" value={formData.name} onChange={handleInput} placeholder={`Entrez le nom du ${NameAlisa}`} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Nom D'utilisateur</label>
                            <input type="text" className="form-control" required="required" id="email" name="email" value={formData.email} onChange={handleInput} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Mot de passe</label>
                            <input type="password" className="form-control" required="required" id="password" name="password" value={formData.password} onChange={handleInput} />
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="isAdmin" checked={formData.isAdmin} onChange={handleInput} id="isAdmin" />
                            <label className="form-check-label" htmlFor="isAdmin">
                                Admin
                            </label>
                        </div>
                        <div className="form-group">
                            <Link to={BackLink} className='btn btn-space btn-danger'>Retour</Link>
                            <button type="submit" className="btn btn-primary">

                                {loading ? <LoadingButton /> : "Ajouter"}

                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Wrapper>
}


const Wrapper = styled.main`
  /* color:red; */

  /* display: grid;
  gap: 4rem;
  img {
    width: 100%;
    display: block;
    border-radius: var(--radius);
    height: 500px;
    object-fit: cover;
  } */
 
`

export default Add
