import React, { useEffect } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import Loading from '../../components/Loading'
import Error from '../../components/Error'
import { useSelector, useDispatch } from 'react-redux';
import { deleteSingleUSERS } from '../../store/users_slice'
import { toast } from 'react-toastify';



const Delete = () => {

    const { id } = useParams();

    // const { deleteSingleUSERS: deleteSingleFunction, delete_single_USERS_loading: loading, delete_single_USERS_error: error } = useUserContext();

    const { error, loading, success } = useSelector(state => state.users);

    const dispatch = useDispatch();

    const BackLink = "/USERS"


    useEffect(() => {

        dispatch(deleteSingleUSERS(id))

    }, [id, dispatch])

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <Error msg={error} />
    }

    if (success) {
        toast.success("Utilisateur supprimé  avec succès", { theme: "colored" });
        return <Navigate replace to={BackLink} />
    }

}
export default Delete;