import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Error from '../../components/Error'
import Loading from '../../components/Loading'

import Table from "../../components/Table";
import { useSelector, useDispatch } from 'react-redux';
import { fetchCOLORS as fetchData } from '../../store/colors_slice'
import DeleteButton from '../../components/DeleteButton';


const COLORS = () => {


  const { error, loading, colors } = useSelector(state => state.colors);
  const dispatch = useDispatch();


  // const { fetchCOLORS: fetchData, COLORS, COLORS_error: error, COLORS_loading: loading } = useGlobalContext();
  const NameAlisa = "Couleur";
  const NameAlisPlurals = "Couleurs";

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <Error />
  }

  const dataSet = colors;

  const columns = [
    {
      "data": "name",
      "title": "Nom du " + NameAlisa,
      "width": "40%"
    },
    {
      "data": "code",
      "title": "Code",
      "width": "40%",
      "render": function (data) {
        return <div className='color-btn' style={{ background: data }}></div>
      }
    },
    {
      "data": "_id",
      "title": "Actions",
      "render": function (data) {
        return (
          <>
            <Link to={`/COLORS/edit/${data}`} className='btn btn-success mx-2  btn-sm'>Modifier</Link>
            <DeleteButton to={`/COLORS/delete/${data}`} />

          </>
        )
      }
    },
  ];


  return <main>
    <div className="container-fluid px-4">
      <h1 className="mt-4">Gestion des {NameAlisPlurals}</h1>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item active"></li>
      </ol>


      <div className="card mb-4">
        <div className="card-header">
          <i className="fas fa-table me-1"></i>
          Liste des {NameAlisPlurals}
          <Link to={`/COLORS/add/`} className="btn btn-primary btn-sm float-end">Créer</Link>
        </div>
        <div className="card-body">
          <Table data={dataSet} columns={columns} />
        </div>
      </div>
    </div>
  </main>
}

export default COLORS
