import { createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { products_url as api_url } from "../utils/constants";



const productsSlice = createSlice({
    name: "products",
    initialState: {
        data: false,//to store the added & the edited & all 
        loading: false,
        error: false,
        success: false,
        stats: false
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = true
            state.error = false
            state.success = false
        },
        setError: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.success = false
        },
        setData: (state, action) => {

            state.data = action.payload.data
            state.loading = false
            state.error = false
            state.success = action.payload.success
        },
        setAllData: (state, action) => {
            state.data = action.payload
            state.loading = false
            state.error = false
            state.success = false
        },
        setSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.success = action.payload
        },
        setStats: (state, action) => {
            state.stats = action.payload
        },
        updateStockData: (state, action) => {

            const current_state = current(state);
            const quantity = action.payload.data.quantity;
            const color_id = action.payload.color;
            const size_id = action.payload.size;
            const product_id = action.payload.product_id;

            const tempItem = current_state.data.stockItems.find((i) => (i._id === product_id + color_id + size_id))
            if (tempItem) {

                const tempStockItems = current_state.data.stockItems.map((si) => {
                    if (si._id === product_id + color_id + size_id) {
                        return { ...si, quantity: quantity };
                    } else {
                        return si;
                    }
                })
                // tempItem.amount += amount;
                // console.log(tempItem);
                state.data = { ...current_state.data, stockItems: tempStockItems }

            } else {
                const newItem = {
                    _id: product_id + color_id + size_id,
                    color_id: color_id,
                    size_id: size_id,
                    product_id: product_id,
                    quantity: quantity
                }
                state.data = { ...current_state.data, stockItems: [...current_state.data.stockItems, newItem] }

            }

            // let temp_data = current_state.data.stockItems.map((row, index) => {
            //     if (index === item_index) {
            //         return { ...row, ...item_data }
            //     } else {
            //         return row;
            //     }
            // })
            // state.data = { ...current_state.data, orderItems: temp_data }
        },
    }
})
export default productsSlice;

// export const colorsActions = colorsSlice.actions; option 1 
export const { setError, setLoading, setData, setStats, setAllData, setSuccess, updateStockData } = productsSlice.actions;// option 2

export const add = data => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {


        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const response = await axios.post(api_url, data, config, {

            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        const res_data = response.data;
        await dispatch(setData({ data: res_data, success: true }))
    }
    catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }
}

export const fetchSingleData = id => async (dispatch, getState) => {
    dispatch(setLoading(true));

    try {
        const response = await axios.get(`${api_url}${id}`);
        const res_data = response.data;
        // console.log(res_data);
        dispatch(setData({ data: res_data, success: false }));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));

    }

}

export const fetchAllData = () => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const response = await axios.get(api_url);
        const res_data = response.data;
        // console.log(COLORS);

        dispatch(setAllData(res_data));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));

    }

}
export const fetchAllStocks = () => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {

        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };


        const response = await axios.get(`${api_url}stockglobal`,config);
        const res_data = response.data;
        // console.log(COLORS);

        dispatch(setAllData(res_data));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));

    }

}
export const fetchStats = () => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {


        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const response = await axios.get(`${api_url}stats`, config);
        const res_data = response.data;
        dispatch(setStats(res_data));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }

}

export const deleteSingleData = (id) => async (dispatch, getState) => {
    dispatch(setLoading(true));

    try {

        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        await axios.delete(`${api_url}${id}`, config);
        dispatch(setSuccess(true));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }

}

export const editData = (id, data) => async (dispatch, getState) => {
    dispatch(setLoading())
    try {

        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const response = await axios.put(`${api_url}${id}`, data, config, {

            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        const res_data = response.data;

        dispatch(setData({ data: res_data, success: true }))
    }
    catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }
}

export const editStockData = (id, data) => async (dispatch, getState) => {
    dispatch(setLoading())
    try {

        const { users: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        
        const response = await axios.put(`${api_url}updateStock/${id}`, data, config);
        const res_data = response.data;

        dispatch(setData({ data: res_data, success: true }))
    }
    catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }
}