import React from 'react'
import { API_URL } from '../utils/constants';
import { formatDecimal } from '../utils/helpers';
import Table from './Table'
function TopProducts({ data }) {



    const dataSet = data;

    const columns = [
        {
            "data": "_id",
            "title": "Image",
            //   "width": "80%"
            "render": function (data) {
                return data?.image && <img src={`${API_URL}${data?.image}`} width="100" height="100" />
            }
        },
        {
            "data": "_id",
            "title": "Nom",
            //   "width": "80%"
            "render": function (data) {
                return data?.name
            }
        },

        {
            "data": "sum_sales",
            "title": "Ventes",
            //   "width": "80%"
            "render": function (data) {
                return formatDecimal(data)
            }
        },
        {
            "data": "sum_units",
            "title": "Unités Vendeus",
            //   "width": "80%"
            // "render": function (data) {
            //     return (data)
            // }
        },

    ];


    return <>
        <Table data={dataSet} columns={columns} searching={false} bLengthChange={false} pageLength={5} id="top_products" info={false} />
    </>
}

export default TopProducts