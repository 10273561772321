import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import FormMessage from '../../components/FormMessage'
import { Link } from 'react-router-dom'
import LoadingButton from '../../components/LoadingButton';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import { add } from '../../store/size_slice';

const Add = () => {

  const { loading, error, success } = useSelector(state => state.sizes);
  const dispatch = useDispatch();



  const NameAlisa = "Taille";
  const NameAlisPlurals = "Tailles";
  const BackLink = "/sizes"


  const [color, setColor] = useState("#ebabe8");

  const [formData, setFormData] = useState({
    name: '',
    code: "#ebabe8",
    message: "",
    msgType: "",
  });

  useEffect(() => {
    if (success) {
      toast.success(NameAlisa + " ajoutée avec succès", { theme: "colored" });
    }

    if (error) {
      toast.error(error, { theme: "colored" });
    }
  }, [success, error])


  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  };

  const FormHandler = (e) => {
    e.preventDefault();
    const data = {
      name: formData.name,
      code: formData.code,
    }
    dispatch(add(data));
  };







  return <Wrapper>
    <div className="container-fluid px-4">

      <h1 className="mt-4">Gestion des {NameAlisPlurals}</h1>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item active"></li>
      </ol>


      <div className="card mb-4">
        <div className="card-header">
          <i className="fas fa-table me-1"></i>
          Nouveau {NameAlisa}
        </div>
        <div className="card-body">

          <FormMessage message={formData.message} type={formData.msgType} />

          <form onSubmit={FormHandler} >
            <div className="form-group">
              <label htmlFor="name">Nom</label>
              <input type="text" className="form-control" required="required" id="name" name="name" value={formData.name} onChange={handleInput} placeholder={`Entrez le nom du ${NameAlisa}`} />
            </div>
           
            <div className="form-group">
              <Link to={BackLink} className='btn btn-space btn-danger'>Retour</Link>
              <button type="submit" className="btn btn-primary">

                {loading ? <LoadingButton /> : "Ajouter"}

              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Wrapper>
}


const Wrapper = styled.main`
  /* color:red; */

  /* display: grid;
  gap: 4rem;
  img {
    width: 100%;
    display: block;
    border-radius: var(--radius);
    height: 500px;
    object-fit: cover;
  } */
 
`

export default Add
