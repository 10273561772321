import { createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { useEffect } from "react";
import { orders_url as api_url } from "../utils/constants";

// const config = 


const ordersSlice = createSlice({
    name: "orders",
    initialState: {
        data: false,//to store the added & the edited & all 
        loading: true,
        error: false,
        success: false,
        stats: false,

    },
    reducers: {
        setStats: (state, action) => {
            state.stats = action.payload

        },
        setLoading: (state, action) => {
            state.loading = true
            state.error = false
            state.success = false
        },
        setError: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.success = false
        },
        setData: (state, action) => {

            console.log(action.payload);
            state.data = action.payload.data
            state.loading = false
            state.error = false
            state.success = action.payload.success
        },
        updateTotal: (state, action) => {
            let total_amount = 0;
            let total_items = 0;
            const current_state = current(state);
            console.log(current_state?.data);

            current_state?.data?.orderItems?.map((item, index) => {
                total_amount += Number(item.amount) * Number(item.price);
                total_items += Number(item.amount);
            })
            console.log(total_amount);

            const discount = current_state?.data?.discount;
            const shipping_fees = current_state?.data?.shipping_fees;

            const total = (Number(shipping_fees) + (Number(total_amount) * (1 - discount / 100))).toFixed(3);



            state.data = { ...current_state.data, total_amount, total, total_items }

        },
        updateItemData: (state, action) => {


            const current_state = current(state);
            const item_data = action.payload.data;
            const item_index = action.payload.index;
            console.log(item_data);
            let temp_data = current_state.data.orderItems.map((row, index) => {
                if (index === item_index) {
                    return { ...row, ...item_data }
                } else {
                    return row;
                }
            })
            state.data = { ...current_state.data, orderItems: temp_data }
        },
        deleteOrderItem: (state, action) => {

            console.log(state, action);

            const current_state = current(state);
            // const item_data = action.payload.data;
            const item_index = action.payload.index;

            const item_data = current_state.data.orderItems.filter((it, ind) => ind !== item_index)

            if (item_data.length === 0) {
                console.log(item_data);
                state.error = "Vous ne pouvez pas supprimer le dernier ligne de la commande ";
            } else {
                state.data = { ...current_state.data, orderItems: item_data }
            }
        },
        addNewRow: (state, action) => {


            const current_state = current(state);
            // const item_index = action.payload.index;
            let item_data = { ...current_state.data.orderItems[0] }
            delete item_data._id;
            item_data.amount = 1;
            item_data.size_id = "";
            item_data.color_id = "";
            item_data.product_id = "";
            item_data.price = 0;
            item_data.name = "";
            item_data.image = null;
            console.log(item_data);
            state.data.orderItems = [...current_state.data.orderItems, item_data]

            // if (item_data.length === 0) {
            //     console.log(item_data);
            //     state.error = "Vous ne pouvez pas supprimer le dernier ligne de la commande ";
            // } else {
            //     state.data = { ...current_state.data, orderItems: item_data }
            // }
        },
        setAllData: (state, action) => {
            state.data = action.payload
            state.loading = false
            state.error = false
            state.success = false
        },
        setSuccess: (state, action) => {
            state.loading = false
            state.error = false
            state.success = action.payload
        }
    }
})
export default ordersSlice;






// export const colorsActions = colorsSlice.actions; option 1 
export const { setError, setLoading, setData, setStats, updateTotal, addNewRow, deleteOrderItem, updateItemData, setAllData, setSuccess } = ordersSlice.actions;// option 2

export const add = data => async (dispatch, getState)  => {
    dispatch(setLoading(true));
    try {

        const { users: { userInfo } } = getState();
        const config = {headers: {Authorization: `Bearer ${userInfo.token}`}};

        const response = await axios.post(api_url, data, config);
        const res_data = response.data;
        await dispatch(setData({ data: data, success: true }))
    }
    catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }
}

export const fetchSingleData = id => async dispatch => {
    dispatch(setLoading(true));

    try {

        const response = await axios.get(`${api_url}${id}`);
        const res_data = response.data;

        dispatch(setData({ data: res_data, success: false }));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));

    }

}

export const fetchAllData = () => async (dispatch, getState)  => {
    dispatch(setLoading(true));
    try {
        const { users: { userInfo } } = getState();
        const config = {headers: {Authorization: `Bearer ${userInfo.token}`}};

        const response = await axios.get(api_url, config);
        const res_data = response.data;
        // console.log(COLORS);

        dispatch(setAllData(res_data));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));

    }

}
export const fetchAllDataWaitingShip = () => async (dispatch, getState)  => {
    dispatch(setLoading(true));
    try {
        const { users: { userInfo } } = getState();
        const config = {headers: {Authorization: `Bearer ${userInfo.token}`}};

        const response = await axios.get(`${api_url}waitingship`, config);
        const res_data = response.data;
        // console.log(COLORS);

        dispatch(setAllData(res_data));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));

    }

}

export const deleteSingleData = (id) => async (dispatch, getState)  => {
    dispatch(setLoading(true));

    try {
        const { users: { userInfo } } = getState();
        const config = {headers: {Authorization: `Bearer ${userInfo.token}`}};

        await axios.delete(`${api_url}${id}`, config);
        dispatch(setSuccess(true));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }

}

export const editData = (id, data) => async (dispatch, getState)  => {
    dispatch(setLoading())
    try {
        const { users: { userInfo } } = getState();
        const config = {headers: {Authorization: `Bearer ${userInfo.token}`}};

        const response = await axios.put(`${api_url}${id}`, data, config);
        const res_data = response.data;

        dispatch(setData({ data: res_data, success: true }))
    }
    catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }
}

export const markAllOrdersShipped = () => async (dispatch, getState)  => {
    dispatch(setLoading())
    try {
        const { users: { userInfo } } = getState();
        const config = {headers: {Authorization: `Bearer ${userInfo.token}`}};

        const response = await axios.put(`${api_url}markAllOrdersShipped`,{}, config);
        const res_data = response.data;

        dispatch(setData({ data: res_data, success: true }))
    }
    catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }
}

export const fetchStats = () => async (dispatch, getState) => {

    dispatch(setLoading(true));
    try {

        const { users: { userInfo } } = getState();
        const config = {headers: {Authorization: `Bearer ${userInfo.token}`}};


        const response = await axios.get(`${api_url}stats`, config);
        const res_data = response.data;
        dispatch(setStats(res_data));

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch(setError(message));
    }

}
