import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { postLogout } from "./store/users_slice";

const PrivateRoute = ({ children }) => {

    // const { userInfo } = useUserContext();
    const { userInfo } = useSelector(state => state.users);

    let location = useLocation();
    const dispatch = useDispatch();
    const session_time = (new Date().getTime() - userInfo?.timestamp) / 1000 / 60 / 60;//in hours 

    if (session_time > 24) {
        dispatch(postLogout())
    }
    if (userInfo && userInfo.isAdmin && session_time < 24) {
        return children
    }

    console.log(location);
    return <Navigate to={`/login`} state={{ from: location }} />
}

export default PrivateRoute; 