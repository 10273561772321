import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Error from '../../components/Error'
import Loading from '../../components/Loading'

import Table from "../../components/Table";
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllData } from '../../store/size_slice'
import DeleteButton from '../../components/DeleteButton';


const List = () => {


  const { error, loading, data } = useSelector(state => state.sizes);
  const dispatch = useDispatch();


  // const { fetchCOLORS: fetchData, COLORS, COLORS_error: error, COLORS_loading: loading } = useGlobalContext();
  const NameAlisa = "Taille";
  const NameAlisPlurals = "Tailles";
  const add_edit_delete_link = "Sizes" // root

  useEffect(() => {
    dispatch(fetchAllData());
  }, [dispatch]);

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <Error />
  }

  const dataSet = data;

  const columns = [
    {
      "data": "name",
      "title": "Nom du " + NameAlisa,
      "width": "80%"
    },
    {
      "data": "_id",
      "title": "Actions",
      "render": function (data) {
        return (
          <>
            <Link to={`/${add_edit_delete_link}/edit/${data}`} className='btn btn-success mx-2  btn-sm'>Modifier</Link>
            <DeleteButton to={`/${add_edit_delete_link}/delete/${data}`} />
          </>
        )
      }
    },
  ];


  return <main>
    <div className="container-fluid px-4">
      <h1 className="mt-4">Gestion des {NameAlisPlurals}</h1>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item active"></li>
      </ol>


      <div className="card mb-4">
        <div className="card-header">
          <i className="fas fa-table me-1"></i>
          Liste des {NameAlisPlurals}
          <Link to={`/${add_edit_delete_link}/add/`} className="btn btn-primary btn-sm float-end">Créer</Link>
        </div>
        <div className="card-body">
          <Table data={dataSet} columns={columns} />
        </div>
      </div>
    </div>
  </main>
}

export default List
